import { Col } from 'reactstrap';
import React from 'react';
import { ItemField, ItemFieldType } from './types';
import { calculateCellSize } from '../../../lib/grid';
import { ViewTextField } from '../../fields/ViewTextField';
import { ViewDateField } from '../../fields/ViewDateField';
import ViewItemTable from '../../tables/ViewItemTable';

export const mapViewField = (params: {
    field: ItemField;
    item: Record<string, any>;
    totalSizedCellsWidth: number;
    notSizedCellsCount: number;
    isDisabled?: boolean;
}) => {
    const { field, totalSizedCellsWidth, notSizedCellsCount, item } = params;

    const cellSize = calculateCellSize(totalSizedCellsWidth, notSizedCellsCount, field.size);

    if (field.type === ItemFieldType.TEXT || field.type === ItemFieldType.TEXT_WITH_TOOLTIP) {
        return (
            <Col key={field.fieldName} md={cellSize} className="mb-2">
                <ViewTextField
                    id={`${field.fieldName}`}
                    isShowTooltip={field.type === ItemFieldType.TEXT_WITH_TOOLTIP}
                    title={field.title}
                    value={item[field.fieldName]}
                    isDisabled={field.isEditFormViewField}
                />
            </Col>
        );
    }

    if (field.type === ItemFieldType.DATE) {
        return (
            <Col key={field.fieldName} md={cellSize} className="mb-2">
                <ViewDateField
                    isDisabled={field.isEditFormViewField}
                    title={field.title}
                    value={item[field.fieldName]}
                />
            </Col>
        );
    }

    if (field.type === ItemFieldType.TABLE) {
        return (
            <Col key={field.fieldName} md={cellSize} className="mb-2">
                <ViewItemTable
                    fieldName={field.fieldName}
                    listColumns={field.listColumns}
                    title={field.title}
                    data={item[field.fieldName]}
                />
            </Col>
        );
    }

    return null;
};
