import { DictionaryParams } from '../../types/dictionary';

interface BaseFilter {
    name: string;
    title: string;
    isEnabled: boolean;
    disableClose?: boolean;
}

export interface SimpleFilter extends BaseFilter {
    type: FilterInputType.DATE | FilterInputType.TEXT | FilterInputType.NUMBER;
}

export interface SelectFilter extends BaseFilter {
    type: FilterInputType.SELECT;
    values: Array<string | number>;
}

export interface DictionaryFilter extends BaseFilter {
    type: FilterInputType.DICTIONARY;
    dictionary: DictionaryParams;
}

export type Filter = SelectFilter | SimpleFilter | DictionaryFilter;

export enum FilterInputType {
    TEXT = 'text',
    NUMBER = 'number',
    DICTIONARY = 'dictionary',
    DATE = 'date',
    SELECT = 'select',
}

export interface IDatePeriod {
    from?: string;
    to?: string;
}
