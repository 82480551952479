import React from 'react';
import classnames from 'classnames';
import { Collapse, Container, Navbar, Nav, NavItem } from 'reactstrap';
import Breadcrumbs from '../../Breadcrumbs';
import { UserBar } from './UserBar';

interface INavbarProps {
    toggleSidenav: () => void;
    sidenavOpen: boolean;
    theme: 'dark' | 'light';
}

const AdminNavbar: React.FC<INavbarProps> = ({ toggleSidenav, sidenavOpen, theme }) => {
    return (
        <Navbar
            className={classnames(
                'navbar-top navbar-expand border-bottom',
                { 'navbar-dark bg-info': theme === 'dark' },
                { 'navbar-light bg-secondary': theme === 'light' },
            )}
        >
            <Container fluid={true}>
                <Collapse navbar={true} isOpen={true}>
                    <Breadcrumbs />
                    <Nav className="align-items-center ml-md-auto" navbar={true}>
                        <NavItem className="d-xl-none">
                            <div
                                className={classnames(
                                    'pr-3 sidenav-toggler',
                                    { active: sidenavOpen },
                                    { 'sidenav-toggler-dark': theme === 'dark' },
                                )}
                                onClick={toggleSidenav}
                            >
                                <div className="sidenav-toggler-inner">
                                    <i className="sidenav-toggler-line" />
                                    <i className="sidenav-toggler-line" />
                                    <i className="sidenav-toggler-line" />
                                </div>
                            </div>
                        </NavItem>
                    </Nav>
                </Collapse>
            </Container>
            <UserBar />
        </Navbar>
    );
};

AdminNavbar.defaultProps = {
    toggleSidenav: () => {},
    sidenavOpen: false,
    theme: 'dark',
};

export default AdminNavbar;
