import React from 'react';
import { observer } from 'mobx-react-lite';
import TextInput from 'components/filters/components/TextInput';
import NumberInput from 'components/filters/components/NumberInput';
import DateSelect from 'components/filters/components/DateSelect';
import { FiltersContainer } from 'elements/filters';
import FilterSelector from 'components/filters/components/FilterSelector';
import { Filter, FilterInputType, IDatePeriod } from 'components/filters/types';
import { ListColumn } from 'components/tables/ItemsListTable/types';
import { useFilters } from 'components/filters/useFilters';
import { Item } from 'types/item.enum';
import ValueSelector from './components/ValueSelector';
import DictionarySelector from './components/DictionarySelector';

export interface FiltersToolkitProps {
    listColumns: ListColumn[];
    onFilterChange: (filterName: string, value: string | IDatePeriod) => void;
    onFilterClose: (filterName: string) => void;
    onCloseAllFilters: () => void;
    itemName: Item;
    extraFilters?: Filter[];
}

const FiltersToolkit: React.FC<FiltersToolkitProps> = observer(
    ({ listColumns, onFilterChange, onCloseAllFilters, onFilterClose, itemName, extraFilters }) => {
        const { filters, toggleFilter, closeAllFilters, filterValues } = useFilters({
            onCloseAllFilters,
            onFilterClose,
            listColumns,
            itemName,
            extraFilters,
        });

        if (!filters.length) {
            return null;
        }

        return (
            <FiltersContainer>
                {filters
                    .filter((filter) => filter.isEnabled)
                    .map((filter) => {
                        if (filter.type === FilterInputType.NUMBER) {
                            return (
                                <NumberInput
                                    label={filter.title.toUpperCase()}
                                    handleClose={() => toggleFilter(filter.name)}
                                    onChange={(value) => onFilterChange(filter.name, value)}
                                    key={filter.name}
                                    isHideCloseButton={filter.disableClose}
                                    defaultValue={filterValues?.[filter.name]}
                                />
                            );
                        }

                        if (filter.type === FilterInputType.TEXT) {
                            return (
                                <TextInput
                                    label={filter.title.toUpperCase()}
                                    handleClose={() => toggleFilter(filter.name)}
                                    onChange={(value) => onFilterChange(filter.name, value)}
                                    key={filter.name}
                                    isHideCloseButton={filter.disableClose}
                                    defaultValue={filterValues?.[filter.name]}
                                />
                            );
                        }

                        if (filter.type === FilterInputType.DATE) {
                            return (
                                <DateSelect
                                    label={filter.title.toUpperCase()}
                                    handleClose={() => toggleFilter(filter.name)}
                                    onChange={(value) => onFilterChange(filter.name, value)}
                                    key={filter.name}
                                    isHideCloseButton={filter.disableClose}
                                    defaultValue={filterValues?.[filter.name]}
                                />
                            );
                        }

                        if (filter.type === FilterInputType.SELECT) {
                            return (
                                <ValueSelector
                                    values={filter.values}
                                    label={filter.title.toUpperCase()}
                                    handleClose={() => toggleFilter(filter.name)}
                                    onChange={(value) => onFilterChange(filter.name, value)}
                                    key={filter.name}
                                    isHideCloseButton={filter.disableClose}
                                    defaultValue={filterValues?.[filter.name]}
                                />
                            );
                        }

                        if (filter.type === FilterInputType.DICTIONARY) {
                            return (
                                <DictionarySelector
                                    dictionary={filter.dictionary}
                                    label={filter.title.toUpperCase()}
                                    handleClose={() => toggleFilter(filter.name)}
                                    onChange={(value) => onFilterChange(filter.name, value)}
                                    key={filter.name}
                                    isHideCloseButton={filter.disableClose}
                                    defaultValue={filterValues?.[filter.name]}
                                />
                            );
                        }

                        return null;
                    })}
                <FilterSelector onFilterToggle={toggleFilter} onClickCloseAll={closeAllFilters} filters={filters} />
            </FiltersContainer>
        );
    },
);

export default FiltersToolkit;
