import { useEffect, useState } from 'react';

export function useHiddenDisabled(params: { isHiddenProp?: boolean; isDisabledProp?: boolean }) {
    const { isHiddenProp = false, isDisabledProp = false } = params;

    const [isHidden, setIsHidden] = useState(isHiddenProp);

    const [isDisabled, setIsDisabled] = useState(isDisabledProp);

    useEffect(() => {
        setIsHidden(isHiddenProp);
    }, [isHiddenProp]);
    useEffect(() => {
        setIsDisabled(isDisabledProp);
    }, [isDisabledProp]);

    return { isHidden, isDisabled, setIsDisabled, setIsHidden };
}
