import React from 'react';

import { Item } from 'types/item.enum';
import { observer } from 'mobx-react-lite';
import { ItemFieldEditForm } from '../input.types';
import EditForm from './Form';
import { useItemAndDefaultFields } from './useItemAndDefaultFields';
import Notification from '../../notifications/Notification';

interface EditItemFormProps {
    fields: ItemFieldEditForm;
    itemName: Item;
    title: string;
    data?: Record<any, any>;
    sendSearchData?: boolean;
}

const EditItemForm: React.FC<EditItemFormProps> = observer(({ fields, title, itemName, sendSearchData }) => {
    const { defaultValues, isLoaded, item } = useItemAndDefaultFields({ itemName, fields });

    if (!isLoaded || !item) {
        return null;
    }

    return (
        <React.Fragment>
            <Notification />
            <EditForm
                sendSearchData={sendSearchData}
                defaultValues={defaultValues}
                item={item}
                itemName={itemName}
                fields={fields}
                title={title}
            />
        </React.Fragment>
    );
});

export default EditItemForm;
