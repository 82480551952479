import React, { useEffect } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import AdminLayoutComponent from 'layouts/admin/AdminLayout';
import { useStore } from '../../store';

const AdminLayout: React.FC<RouteComponentProps> = (props) => {
    const authStore = useStore('auth');
    const history = useHistory();

    useEffect(() => {
        if (!authStore.isLoggedIn && !authStore.inProgress) {
            authStore.loginByToken();
        }
        if (!authStore.isLoggedIn) {
            history.push('/auth/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authStore.isLoggedIn]);

    return <AdminLayoutComponent {...props} />;
};

export default AdminLayout;
