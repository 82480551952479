import React from 'react';
import { Link } from 'react-router-dom';
import { NoShadowButton } from 'components/buttons/styles';

interface LinkButtonProps {
    to: string;
    title: string;
}

export const LinkButton: React.FC<LinkButtonProps> = ({ to, title }) => (
    <Link to={to}>
        <NoShadowButton size="sm">{title}</NoShadowButton>
    </Link>
);
