import React from 'react';
import { observer } from 'mobx-react-lite';
import { UserBarContainer } from '../../../elements/navs';
import { useStore } from '../../../store';
import IconButton from '../../buttons/IconButton';

export const UserBar: React.FC = observer(() => {
    const adminStore = useStore('admin');
    const authStore = useStore('auth');

    return (
        <UserBarContainer>
            {adminStore.currentUser.username}{' '}
            <IconButton className="fa fa-sign-out" handleClose={() => authStore.logout()} />{' '}
        </UserBarContainer>
    );
});
