import { debounce } from 'lodash';
import { useCallback, useState } from 'react';
import { generateRequestsByItemName } from 'api';
import { GetListParams, ListData } from 'types/list.type';
import { Item } from 'types/item.enum';
import { IDatePeriod } from 'components/filters/types';
import listStore from 'store/list';
import { TableChangeState, TableChangeType } from 'react-bootstrap-table-next';

const FIRST_PAGE = 1;
const DEFAULT_SIZE_PER_PAGE = 10;
const DEFAULT_ORDER = 'desc';

export function useList<
    DATA extends Record<string, any> & { id: number | string },
    FILTERS extends Record<string, any> = Record<string, any>,
>(listName: Item, keyField = 'id', defaultSortField?: string) {
    const [listParams, setListParams] = useState<GetListParams<FILTERS>>({
        page: listStore.lists[listName]?.page || FIRST_PAGE,
        sizePerPage: listStore.lists[listName]?.sizePerPage || DEFAULT_SIZE_PER_PAGE,
        filters: listStore.lists[listName]?.queryFilters as FILTERS,
        sortField: listStore.lists[listName]?.sortField || defaultSortField || keyField,
        sortOrder: listStore.lists[listName]?.sortOrder || DEFAULT_ORDER,
    });
    const [isLoading, setIsLoading] = useState(false);
    const [list, setData] = useState<ListData<DATA>>({ data: null, total: 0 });
    const [dataMap, setDataMap] = useState<Record<number, DATA>>({});
    const { getList, updateItem } = generateRequestsByItemName<DATA, FILTERS>(listName);

    const getListAndUpdateState = useCallback(
        async (params?: GetListParams<FILTERS>) => {
            setIsLoading(true);

            const { data, total } = await getList({ ...listParams, ...params });
            setData({ data, total });
            setDataMap(Object.fromEntries(data!.map((el) => [el[keyField], el])));
            setListParams({ ...listParams, ...params });
            listStore.setListParams(
                {
                    currentPageItemsCount: data?.length,
                    page: params?.page || listParams.page,
                    sizePerPage: params?.sizePerPage || listParams.sizePerPage,
                    queryFilters: params?.filters || listParams.filters,
                    sortField: params?.sortField || listParams.sortField,
                    sortOrder: params?.sortOrder || listParams.sortOrder,
                },
                listName,
            );

            setIsLoading(false);
        },
        [keyField, getList, listName, listParams],
    );

    const handleReloadList = useCallback(
        ({ isDeleted }: { isDeleted?: boolean } = {}) => {
            if (isDeleted) {
                const prevPage = listParams.page - 1 > 0 ? listParams.page - 1 : 1;
                const page = listStore.lists[listName]?.currentPageItemsCount === 1 ? prevPage : listParams.page;

                getListAndUpdateState({ ...listParams, page });

                return;
            }

            getListAndUpdateState(listParams);
        },
        [getListAndUpdateState, listName, listParams],
    );

    const updateFilterDebounced = debounce((newParams) => {
        getListAndUpdateState(newParams);
        setListParams(newParams);
    }, 500);

    const handleFilterChange = (filterName: keyof FILTERS, value: string | IDatePeriod) => {
        let { filters = {} as Record<keyof FILTERS, any> } = listParams;

        if (value) {
            filters = { ...filters, [filterName]: value };
        }

        if (typeof value === 'string' && !value.trim().length && filters[filterName]) {
            delete filters[filterName];
        }

        const newParams = {
            ...listParams,
            page: FIRST_PAGE,
            ...(Object.keys(filters).length && { filters }),
        };

        updateFilterDebounced(newParams);
    };

    const handleFilterClose = (filterName: keyof FILTERS) => {
        setListParams((prevListParams) => {
            const { filters = {} as Record<keyof FILTERS, any> } = prevListParams;

            delete filters[filterName];

            const newParams = {
                ...listParams,
                page: FIRST_PAGE,
                ...(Object.keys(filters).length && { filters }),
            };

            getListAndUpdateState(newParams);

            return newParams;
        });
    };

    const handleCloseAllFilters = () => {
        setListParams((prevListParams) => {
            // eslint-disable-next-line no-param-reassign
            delete prevListParams.filters;

            const newParams = { ...prevListParams, page: FIRST_PAGE };

            getListAndUpdateState(newParams);

            return newParams;
        });
    };

    const updateItemAndReloadList = async (params: { rowId: number | string; dataField: string; newValue: any }) => {
        const { rowId, newValue, dataField } = params;

        if (String(dataMap[rowId][dataField]) === String(newValue)) {
            return;
        }

        await updateItem({ id: rowId }, { [dataField]: newValue });

        await getListAndUpdateState();
    };

    const handleTableChange = async (
        eventName: TableChangeType,
        { cellEdit, sizePerPage, sortField, sortOrder, page }: TableChangeState<DATA>,
    ) => {
        if (eventName === 'cellEdit') {
            const { rowId, newValue, dataField } = cellEdit;
            await updateItemAndReloadList({ rowId, newValue, dataField });

            return;
        }

        await getListAndUpdateState({ sizePerPage, sortField, sortOrder, page });
    };

    return {
        list,
        listParams,
        isLoading,
        getList: getListAndUpdateState,
        reloadList: handleReloadList,
        updateItemAndReloadList,
        handleFilterChange,
        handleFilterClose,
        handleCloseAllFilters,
        handleTableChange,
    };
}
