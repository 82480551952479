import React from 'react';
import styled from 'styled-components';

const StyledI = styled.i<{ isHidden?: boolean }>`
    align-self: center;
    margin-left: 0.5em;
    margin-right: 0.5em;
    color: #d4d4d4;
    cursor: pointer;
    &:hover {
        color: #b1b1b1;
    }
    display: ${({ isHidden }) => (isHidden ? 'none' : 'inline-block')};
`;

interface OkButtonProps {
    onClick: () => void;
    isHidden?: boolean;
    title?: string;
}

const OkButton: React.FC<OkButtonProps> = ({ onClick, isHidden, title }) => {
    const handleClickClose = (e: React.MouseEvent) => {
        e.preventDefault();
        onClick();
    };

    return (
        <StyledI
            title={title}
            isHidden={isHidden}
            aria-hidden="true"
            onClick={handleClickClose}
            className="fa fa-check"
        />
    );
};

export default OkButton;
