import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Col, Row } from 'reactstrap';
import { preparePlainText } from 'lib/text';
import OkButton from 'components/buttons/OkButton';
import { StyledCellInput } from './styles';

interface CellEditTextInputProps {
    id: string;
    value: any;
    onUpdate: (data: any) => void;
    onBlur: () => void;
}

export const CellEditTextInput: React.FC<CellEditTextInputProps> = ({ id, value, onUpdate, onBlur }) => {
    const [innerValue, setValue] = useState(preparePlainText(value));

    const inputEl = useRef<HTMLInputElement>(null);

    const okButtonId = `ok-button-${id}`;

    const handleBlur = useCallback(
        (e: React.FocusEvent<HTMLElement>) => {
            if (e.relatedTarget?.id !== okButtonId) {
                onBlur();
            }
        },
        [okButtonId, onBlur],
    );

    useEffect(() => {
        if (inputEl?.current) {
            inputEl.current.focus();
        }
    }, [inputEl]);

    return (
        <Row onBlur={handleBlur} className="align-items-center justify-content-between flex-nowrap">
            <Col sm={11}>
                <StyledCellInput
                    innerRef={inputEl}
                    value={innerValue}
                    onChange={(e) => setValue(e.target.value)}
                    bsSize="sm"
                    type="text"
                />
            </Col>
            <Col id={okButtonId} tabIndex={0} sm="auto">
                <OkButton
                    onClick={() => {
                        onUpdate(innerValue);
                        onBlur();
                    }}
                />
            </Col>
        </Row>
    );
};
