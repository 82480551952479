import React from 'react';
import { FieldError } from 'react-hook-form';
import { StyledFormError } from 'elements/forms';

interface FormErrorProps {
    error?: FieldError;
}

export const FormError: React.FC<FormErrorProps> = ({ error }) => {
    if (!error) {
        return null;
    }

    return <StyledFormError>{error.message ? error.message : 'Проверьте правильность ввода'}</StyledFormError>;
};
