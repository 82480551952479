export function calculateCellSize(totalSizedCellsWidth: number, notSizedCellsCount: number, cellSize?: number) {
    return cellSize || Math.round((12 - totalSizedCellsWidth) / notSizedCellsCount);
}

export function calculateRowSizeValues(cells: Array<{ size?: number }>) {
    return {
        totalSizedCellsWidth: cells.reduce((acc, el) => (el.size ? acc + el.size : acc), 0),
        notSizedCellsCount: cells.filter((el) => !el.size).length,
    };
}
