import { Dictionary } from '../types/dictionary';
import { SendFieldsFromDictionary } from '../components/forms/input.types';

export const getSelectValueByFieldValue = (
    fieldValue: string | number | string[] | number[] | null | undefined,
    dictionary: Dictionary[],
) => {
    if (!fieldValue) {
        return null;
    }

    if (typeof fieldValue === 'string') {
        const dictionaryItem = dictionary.find((el) => el.title === fieldValue);

        return dictionaryItem ? { value: dictionaryItem.id, label: dictionaryItem.title } : null;
    }

    if (typeof fieldValue === 'number') {
        const dictionaryItem = dictionary.find((el) => el.id === fieldValue);

        return dictionaryItem ? { value: dictionaryItem.id, label: dictionaryItem.title } : null;
    }

    return fieldValue.map((el) => getSelectValueByFieldValue(el, dictionary)).filter((el) => !!el);
};

export const getFieldValueFromDictionary = (dictionary: Dictionary, field: SendFieldsFromDictionary) => {
    if (field === SendFieldsFromDictionary.ID) {
        return dictionary.id;
    }

    if (field === SendFieldsFromDictionary.TITLE) {
        return dictionary.title;
    }

    return dictionary;
};

export const getDictionaryDefaultValueByFieldType = (
    defaultValue: string | number | string[] | number[] | null | undefined,
    fieldType: SendFieldsFromDictionary,
    dictionary: Dictionary[],
) => {
    if (!defaultValue) {
        return null;
    }

    if (defaultValue instanceof Array) {
        return defaultValue.map((el) => getDictionaryDefaultValueByFieldType(el, fieldType, dictionary));
    }

    const dictionaryItem = dictionary.find((el) => el.id === defaultValue || el.title === defaultValue);

    if (!dictionaryItem) {
        return null;
    }

    return getFieldValueFromDictionary(dictionaryItem, fieldType);
};

export const getFieldValueFromSelected = (
    selected: { value: number | string; label: string },
    field: SendFieldsFromDictionary,
) => {
    if (field === SendFieldsFromDictionary.ID) {
        return selected.value;
    }

    if (field === SendFieldsFromDictionary.TITLE) {
        return selected.label;
    }

    return { id: selected.value, title: selected.label };
};
