import { useEffect, useState } from 'react';
import { toJS } from 'mobx';
import { Dictionary, DictionaryParams } from 'types/dictionary';
import { isEqual } from 'lodash';
import { useStore } from '../store';

export function useDictionary(params: DictionaryParams): Dictionary[] {
    const dictionaryStore = useStore('dictionary');
    const [dictType, dictListType] = Object.entries(params)[0];
    const [dictionaryItems, setDictionaryItems] = useState([]);
    const dictionaryItemsInStore = dictionaryStore.items[dictType][dictListType];

    useEffect(() => {
        if (!dictionaryItems.length) {
            dictionaryStore.loadDictionary({ [dictType]: dictListType });
        }
    }, [dictListType, dictType, dictionaryItems.length, dictionaryStore]);

    useEffect(() => {
        if (!dictionaryItems.length && dictionaryItemsInStore) {
            setDictionaryItems((prevValue) => {
                const newDictItems = toJS(dictionaryItemsInStore);
                if (isEqual(prevValue, newDictItems)) {
                    return prevValue;
                }

                return newDictItems;
            });
        }
    }, [dictListType, dictType, dictionaryItems.length, dictionaryItemsInStore]);

    return dictionaryItems;
}
