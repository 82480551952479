import styled from 'styled-components';
import { Card } from 'reactstrap';

export const FormCard = styled(Card)`
    border-radius: 5px !important;
`;

export const StyledFormError = styled.div`
    font-size: 0.875rem;
    color: #fa2719;
    padding-left: 1rem;
    padding-top: 0.25rem;
`;

export const StyledFormInfo = styled.div`
    font-size: 0.875rem;
    color: #5e72e4;
    padding-left: 1rem;
    padding-top: 0.25rem;
`;
