import styled from 'styled-components';
import { Input } from 'reactstrap';

export const StyledCellSelect = styled.select`
    background-color: #fff !important;
    border: 1px solid #dee2e6;
    color: #525f7f;
    padding: 2px 6px 2px 6px;
    height: 2.1em;

    &:focus {
        border: 1px solid #dee2e6;
        box-shadow: none;
        color: #525f7f;
    }
`;

export const StyledCellInput = styled(Input)`
    background-color: #fff !important;
    &:focus {
        border: 1px solid #dee2e6;
        box-shadow: none;
    }
`;

export const StyledFlexColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
