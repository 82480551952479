import React from 'react';

import { TableButtonsToolkitContainer, TableToolkitContainer } from 'components/tables/ItemsListTable/styles';
import FiltersToolkit, { FiltersToolkitProps } from 'components/filters/FiltersToolkit';
import { LinkButton } from 'components/buttons/LinkButton';
import { CREATE_BY_STRING_MASS_ROUTE_POSTFIX, DELETE_BY_STRING_MASS_ROUTE_POSTFIX } from 'routes/constants';

interface TableToolkitProps extends FiltersToolkitProps {
    isShowCreateButton?: boolean;
    isShowMassCreateFromStringButton?: boolean;
    isShowMassDeleteFromStringButton?: boolean;
}

export const TableToolkit: React.FC<TableToolkitProps> = ({
    listColumns,
    onFilterChange,
    onCloseAllFilters,
    onFilterClose,
    itemName,
    isShowCreateButton,
    isShowMassCreateFromStringButton,
    isShowMassDeleteFromStringButton,
    extraFilters,
}) => (
    <TableToolkitContainer>
        <TableButtonsToolkitContainer>
            {isShowCreateButton && <LinkButton title="Создать" to={`/admin/${itemName}/create`} />}
            {isShowMassCreateFromStringButton && (
                <LinkButton title="Создать" to={`/admin/${itemName}/${CREATE_BY_STRING_MASS_ROUTE_POSTFIX}`} />
            )}
            {isShowMassDeleteFromStringButton && (
                <LinkButton title="Удалить" to={`/admin/${itemName}/${DELETE_BY_STRING_MASS_ROUTE_POSTFIX}`} />
            )}
        </TableButtonsToolkitContainer>
        <FiltersToolkit
            itemName={itemName}
            onFilterClose={onFilterClose}
            onCloseAllFilters={onCloseAllFilters}
            onFilterChange={onFilterChange}
            listColumns={listColumns}
            extraFilters={extraFilters}
        />
    </TableToolkitContainer>
);
