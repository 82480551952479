import React from 'react';
import { Item } from 'types/item.enum';
import EditItemForm from 'components/forms/EditItemForm';
import { ItemFieldEditForm, ItemInputFieldType } from 'components/forms/input.types';
import { ItemFieldType } from 'components/forms/ViewItemForm/types';
import { AdminDictionaryId, DictionaryType } from '../../../types/dictionary';

const fields: ItemFieldEditForm = [
    [
        {
            title: 'id',
            fieldName: 'id',
            type: ItemFieldType.TEXT,
            isEditFormViewField: true,
        },
    ],
    [
        {
            title: 'Название',
            fieldName: 'title',
            type: ItemInputFieldType.TEXT,
            rules: {
                required: true,
            },
        },
        {
            title: 'Показывать?',
            fieldName: 'isEnabled',
            type: ItemInputFieldType.BOOLEAN,
            rules: {
                required: true,
            },
        },
    ],
    [
        {
            title: 'Банки',
            fieldName: 'bankIds',
            type: ItemInputFieldType.DICTIONARY_MULTI,
            dictionary: {
                [DictionaryType.ADMIN]: AdminDictionaryId.BANK,
            },
        },
    ],
];

const View: React.FC = () => {
    return (
        <EditItemForm
            sendSearchData={true}
            title="Редактирование страховой компании"
            fields={fields}
            itemName={Item.INSURANCE_COMPANY}
        />
    );
};

export default View;
