import React from 'react';
import Loader from 'components/Loader';
import { Card, CardBody, Container } from 'reactstrap';
import Header from 'components/headers/ItemListHeader';
import Notification from 'components/notifications/Notification';

interface ItemFormCardProps {
    title: string;
    children: React.ReactNode[] | React.ReactNode;
    emptyShowCondition?: boolean;
    loaderShowCondition?: boolean;
    onSubmit?: (data: any) => void;
}

export const ItemFormCard: React.FC<ItemFormCardProps> = ({
    title,
    children,
    emptyShowCondition,
    loaderShowCondition,
    onSubmit,
}) => {
    if (loaderShowCondition) {
        return <Loader />;
    }

    if (emptyShowCondition) {
        return null;
    }

    return (
        <React.Fragment>
            <Container className="mt--6" fluid={true}>
                <Header title={title} />
                <Card>
                    <CardBody className="mb-0">
                        {onSubmit ? <form onSubmit={onSubmit}>{children}</form> : children}
                    </CardBody>
                </Card>
            </Container>
            <Notification />
        </React.Fragment>
    );
};
