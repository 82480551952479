import React from 'react';
import 'assets/css/tooltip.css';
import { UncontrolledTooltip } from 'reactstrap';

interface TableBooleanFieldProps {
    isEnabled: boolean;
    spanId: string;
}

const TableBooleanField: React.FC<TableBooleanFieldProps> = ({ isEnabled, spanId }) => {
    const spanUniqId = `table-boolean-span-id-${spanId}`;

    return (
        <div className="align-items-center">
            <label className="custom-toggle-table custom-toggle-table-table-dark">
                <input readOnly={true} checked={isEnabled} type="checkbox" />
                <span
                    id={spanUniqId}
                    className="custom-toggle-table-slider rounded-circle custom-toggle-table-disabled"
                />
            </label>
            <UncontrolledTooltip popperClassName="tooltip-custom" placement="bottom-start" target={spanUniqId}>
                {String(isEnabled)}
            </UncontrolledTooltip>
        </div>
    );
};

export default TableBooleanField;
