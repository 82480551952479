import React from 'react';
import {
    ISimpleRoute,
    TRouteArray,
    TRoute,
    isCollapsedRoute,
    isSimpleRoute,
    isHiddenRoute,
    ICollapsedRoute,
} from 'routes';
import { useLocation, NavLink as NavLinkRRD, Link } from 'react-router-dom';
import classnames from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav } from 'reactstrap';

interface ISidebarProps {
    // function used to make sidenav mini or normal
    toggleSidenav: () => void;
    // prop to know if the sidenav is mini or normal
    sidenavOpen: boolean;
    // links that will be displayed inside the component
    routes: TRouteArray;
    // logo
    logo?: {
        // innerLink is for links that will direct the user within the app
        // it will be rendered as <Link to="...">...</Link> tag
        innerLink?: string;
        // outterLink is for links that will direct the user outside the app
        // it will be rendered as simple <a href="...">...</a> tag
        outterLink?: string;
        // the image src of the logo
        imgSrc?: string;
        // the alt for the img
        imgAlt?: string;
    };
    // rtl active, this will make the sidebar to stay on the right side
    rtlActive?: boolean;
}

type State = Record<string, boolean>;

const Sidebar: React.FC<ISidebarProps> = ({ toggleSidenav, sidenavOpen, routes, logo, rtlActive }) => {
    const [state, setState] = React.useState<State>({});
    const location = useLocation();

    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName: string) => {
        return location.pathname.endsWith(routeName) || location.pathname.indexOf(`${routeName}/`) > -1 ? 'active' : '';
    };
    // makes the sidenav normal on hover (actually when mouse enters on it)
    const onMouseEnterSidenav = () => {
        if (!document.body.classList.contains('g-sidenav-pinned')) {
            document.body.classList.add('g-sidenav-show');
        }
    };
    // makes the sidenav mini on hover (actually when mouse leaves from it)
    const onMouseLeaveSidenav = () => {
        if (!document.body.classList.contains('g-sidenav-pinned')) {
            document.body.classList.remove('g-sidenav-show');
        }
    };

    // this verifies if any of the collapses should be default opened on a rerender of this component
    // for example, on the refresh of the page,
    // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
    const getCollapseInitialState = (routesForCollapseInitialState: TRoute[]) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const route of routesForCollapseInitialState) {
            if (isCollapsedRoute(route) && route.collapse && getCollapseInitialState(route.views)) {
                return true;
            }
            if (isSimpleRoute(route) && location.pathname.indexOf(route.path) !== -1) {
                return true;
            }
        }

        return false;
    };

    // this creates the initial state of this component based on the collapse routes
    // that it gets through routes
    const getCollapseStates = (routesForCollapsedStates: TRoute[]) => {
        let initialState = {};
        routesForCollapsedStates.map((route) => {
            if (isCollapsedRoute(route) && route.collapse) {
                initialState = {
                    [route.state]: getCollapseInitialState(route.views),
                    ...getCollapseStates(route.views),
                    ...initialState,
                };
            }

            return null;
        });

        return initialState;
    };

    // this is used on mobile devices, when a user navigates
    // the sidebar will autoclose
    const closeSidenav = () => {
        if (window.innerWidth < 1200) {
            toggleSidenav();
        }
    };

    React.useEffect(() => {
        setState(getCollapseStates(routes));
        closeSidenav();
        // eslint-disable-next-line
    }, []);

    const getRouteStyledTitle = (route: ISimpleRoute | ICollapsedRoute): JSX.Element | undefined => {
        let routeTitle;

        if (route.icon) {
            routeTitle = (
                <React.Fragment>
                    <i className={route.icon} />
                    <span className="nav-link-text">{route.name}</span>
                </React.Fragment>
            );
        } else if (route.miniName) {
            routeTitle = (
                <React.Fragment>
                    <span className="sidenav-mini-icon"> {route.miniName} </span>
                    <span className="sidenav-normal"> {route.name} </span>
                </React.Fragment>
            );
        }

        return routeTitle;
    };

    // this function creates the links and collapses that appear in the sidebar (left menu)
    const createLinks = (routesForLinks: TRoute[]) => {
        return routesForLinks.map((route): JSX.Element | null => {
            if (isCollapsedRoute(route) && route.collapse) {
                const st: State = {};
                st[route.state] = !state[route.state];

                return (
                    <NavItem key={route.name}>
                        <NavLink
                            href="#pablo"
                            data-toggle="collapse"
                            aria-expanded={state[route.state]}
                            className={classnames({
                                active: getCollapseInitialState(route.views),
                            })}
                            onClick={(e) => {
                                e.preventDefault();
                                setState(st);
                            }}
                        >
                            {getRouteStyledTitle(route) || null}
                        </NavLink>
                        <Collapse isOpen={state[route.state]}>
                            <Nav className="nav-sm flex-column">{createLinks(route.views)}</Nav>
                        </Collapse>
                    </NavItem>
                );
            }

            if (isHiddenRoute(route)) {
                return null;
            }

            if (isSimpleRoute(route)) {
                return (
                    <NavItem className={activeRoute(route.layout + route.path)} key={route.layout + route.path}>
                        <NavLink
                            to={route.layout + route.path}
                            activeClassName=""
                            onClick={closeSidenav}
                            tag={NavLinkRRD}
                        >
                            {getRouteStyledTitle(route) || route.name}
                        </NavLink>
                    </NavItem>
                );
            }

            return null;
        });
    };

    let navbarBrandProps;
    if (logo && logo.innerLink) {
        navbarBrandProps = {
            to: logo.innerLink,
            tag: Link,
        };
    } else if (logo && logo.outterLink) {
        navbarBrandProps = {
            href: logo.outterLink,
            target: '_blank',
        };
    }
    const scrollBarInner = (
        <div className="scrollbar-inner">
            <div className="sidenav-header d-flex align-items-center">
                {logo ? (
                    <NavbarBrand {...navbarBrandProps}>
                        <img alt={logo.imgAlt} className="navbar-brand-img" src={logo.imgSrc} />
                    </NavbarBrand>
                ) : null}
                <div className="ml-auto">
                    <div
                        className={classnames('sidenav-toggler d-none d-xl-block', {
                            active: sidenavOpen,
                        })}
                        onClick={toggleSidenav}
                        onKeyDown={toggleSidenav}
                    >
                        <div className="sidenav-toggler-inner">
                            <i className="sidenav-toggler-line" />
                            <i className="sidenav-toggler-line" />
                            <i className="sidenav-toggler-line" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="navbar-inner">
                <Collapse navbar={true} isOpen={true}>
                    <Nav navbar={true}>{createLinks(routes)}</Nav>
                </Collapse>
            </div>
        </div>
    );

    return (
        <Navbar
            className={`sidenav navbar-vertical navbar-expand-xs navbar-light bg-white ${
                rtlActive ? '' : 'fixed-left'
            }`}
            onMouseEnter={onMouseEnterSidenav}
            onMouseLeave={onMouseLeaveSidenav}
        >
            {navigator.platform.indexOf('Win') > -1 ? (
                <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
            ) : (
                scrollBarInner
            )}
        </Navbar>
    );
};

Sidebar.defaultProps = {
    routes: [{}] as TRouteArray,
    toggleSidenav: () => {},
    sidenavOpen: false,
    rtlActive: false,
};

export default Sidebar;
