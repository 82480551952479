import React from 'react';
import { Control, useController, UseControllerProps } from 'react-hook-form';
import { StyledDisabledInput, StyledInput } from 'components/fields/styles';
import { getTitleWithAsterisk } from 'lib/text';
import { getErrorByName } from 'lib/form';
import { FormError } from 'components/forms/FormError';
import { FormInfo } from '../forms/FormInfo';
import { useHiddenDisabled } from './useHiddenDisabled';

interface FormTextInputProps {
    title: string;
    name: UseControllerProps['name'];
    rules?: UseControllerProps['rules'];
    control: Control<any>;
    defaultValue?: string;
    info?: string;
    isDisabledProp?: boolean;
    sendNull?: boolean;
    isHiddenProp?: boolean;
}

export const FormNumberInput: React.FC<FormTextInputProps> = ({
    title,
    rules,
    control,
    name,
    defaultValue,
    info,
    isDisabledProp = false,
    sendNull,
    isHiddenProp,
}) => {
    const {
        field,
        formState: { errors },
    } = useController({ rules, control, name, defaultValue: defaultValue || '' });
    const { isHidden, isDisabled } = useHiddenDisabled({ isDisabledProp, isHiddenProp });

    const error = getErrorByName(name, errors);

    const handleChange = (value) => {
        const numberVale = value.target.value.replace(/\D/g, '');

        if (sendNull && !value) {
            field.onChange(null);
        } else {
            field.onChange(numberVale);
        }
    };

    if (isHidden) {
        return null;
    }

    return (
        <div className="align-items-center">
            <label className="form-control-label">{getTitleWithAsterisk(title, !!rules?.required)}</label>
            {isDisabled && <StyledDisabledInput {...field} type="text" />}
            {!isDisabled && <StyledInput {...field} onChange={handleChange} type="text" disabled={isDisabled} />}
            <FormError error={error} />
            <FormInfo text={info} />
        </div>
    );
};
