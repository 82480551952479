import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import 'assets/css/tooltip.css';
import { preparePlainText } from 'lib/text';

interface ITableTextFieldProps {
    spanId: string;
    text: string | any[] | object;
    isShowTooltip?: boolean;
}

const TableTextTooltipField: React.FC<ITableTextFieldProps> = ({ text, isShowTooltip, spanId }) => {
    const spanUniqId = `span-id-${spanId}`;

    return (
        <div className="align-items-center">
            <span id={spanUniqId}>{preparePlainText(text)}</span>
            {isShowTooltip && (
                <UncontrolledTooltip popperClassName="tooltip-custom" placement="bottom-start" target={spanUniqId}>
                    {preparePlainText(text)}
                </UncontrolledTooltip>
            )}
        </div>
    );
};

TableTextTooltipField.defaultProps = {
    isShowTooltip: false,
};

export default TableTextTooltipField;
