import React, { useEffect } from 'react';
import { Control, useController, UseControllerProps } from 'react-hook-form';
import { StyledInput } from 'components/fields/styles';
import { getTitleWithAsterisk } from 'lib/text';
import { getErrorByName } from 'lib/form';
import { FormError } from 'components/forms/FormError';
import { StyledFlexColumnContainer } from 'components/form-inputs/styles';
import { useHiddenDisabled } from './useHiddenDisabled';

interface FromBooleanInputProps {
    title: string;
    name: UseControllerProps['name'];
    rules?: UseControllerProps['rules'];
    control: Control<any>;
    defaultValue?: boolean;
    disableOn?: boolean;
    disableOff?: boolean;
    isDisabledProp?: boolean;
    isHiddenProp?: boolean;
}

export const FromBooleanInput: React.FC<FromBooleanInputProps> = ({
    title,
    rules,
    control,
    name,
    defaultValue,
    disableOn,
    disableOff,
    isDisabledProp,
    isHiddenProp,
}) => {
    const {
        field,
        formState: { errors },
    } = useController({ rules, control, name, defaultValue: defaultValue ?? false });
    const { isHidden, isDisabled, setIsDisabled } = useHiddenDisabled({ isDisabledProp, isHiddenProp });

    const error = getErrorByName(name, errors);

    useEffect(() => {
        if (disableOn && !defaultValue) {
            setIsDisabled(true);
        }

        if (disableOff && defaultValue) {
            setIsDisabled(true);
        }

        if (isDisabledProp) {
            setIsDisabled(isDisabledProp);
        }
    }, [isDisabledProp]);

    if (isHidden) {
        return null;
    }

    return (
        <StyledFlexColumnContainer>
            <label className="form-control-label">{getTitleWithAsterisk(title, !!rules?.required)}</label>
            <label className="custom-toggle custom-toggle-table-dark">
                <StyledInput {...field} type="checkbox" defaultChecked={defaultValue} disabled={isDisabled} />
                <span className="custom-toggle-slider rounded-circle" />
            </label>
            <FormError error={error} />
        </StyledFlexColumnContainer>
    );
};
