import axios from 'axios';
import { addResponseErrorNotifications, addResponseResultNotifications } from './interceptors';

export const getAxiosInstanceWithErrorNotification = (baseUrl: string) => {
    const axiosInstance = axios.create({
        baseURL: baseUrl,
        withCredentials: true,
    });

    addResponseErrorNotifications(axiosInstance);

    return axiosInstance;
};

export const getAxiosInstanceWithResultAndErrorNotification = (baseUrl: string) => {
    const axiosInstance = axios.create({
        baseURL: baseUrl,
        withCredentials: true,
    });

    addResponseResultNotifications(axiosInstance);

    return axiosInstance;
};
