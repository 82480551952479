import React from 'react';
import { ItemFieldCreateForm, ItemInputFieldType } from 'components/forms/input.types';
import { Item } from 'types/item.enum';
import { CreateItemForm } from 'components/forms/CreateItemForm';
import { AdminDictionaryId, DictionaryType } from '../../../types/dictionary';

const fields: ItemFieldCreateForm = [
    [
        {
            title: 'Название банка',
            fieldName: 'title',
            type: ItemInputFieldType.TEXT,
            rules: {
                required: true,
            },
        },
        {
            title: 'Показывать?',
            fieldName: 'isEnabled',
            type: ItemInputFieldType.BOOLEAN,
        },
    ],
    [
        {
            title: 'Страховые компании',
            fieldName: 'insuranceCompanyIds',
            type: ItemInputFieldType.DICTIONARY_MULTI,
            dictionary: {
                [DictionaryType.ADMIN]: AdminDictionaryId.INSURANCE_COMPANY,
            },
        },
    ],
];

const View: React.FC = () => {
    return <CreateItemForm title="Добавить банк" fields={fields} itemName={Item.BANK} />;
};

export default View;
