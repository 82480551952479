import React from 'react';

import { Container, Row, Col } from 'reactstrap';

const AdminFooter: React.FC = () => {
    return (
        <Container fluid={true}>
            <footer className="footer pt-0">
                <Row className="align-items-center justify-content-lg-between">
                    <Col lg="6">
                        <div className="copyright text-center text-lg-left text-muted">
                            © {new Date().getFullYear()}
                            <a className="font-weight-bold ml-1" href="https://" target="_blank" rel="noreferrer">
                                Панель администратора
                            </a>
                        </div>
                    </Col>
                </Row>
            </footer>
        </Container>
    );
};

export default AdminFooter;
