import React from 'react';
import { Card, Col, Container, Row } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
// @ts-ignore
import cellEditFactory from 'react-bootstrap-table2-editor';

import getPagination from 'components/tables/pagination/getPaginationOptions';
import Notification from 'components/notifications/Notification';
import Header from 'components/headers/ItemListHeader';
import { Item } from 'types/item.enum';
import { ListColumn } from 'components/tables/ItemsListTable/types';
import { useColumns } from 'components/tables/useColumns';
import { useList } from 'components/tables/ItemsListTable/useList';
import { TableToolkit } from 'components/tables/ItemsListTable/TableToolkit';
import { Filter } from '../../filters/types';

interface IItemsListTableProps {
    listColumns: ListColumn[];
    listName: Item;
    title: string;
    isShowCreateButton?: boolean;
    isShowMassCreateFromStringButton?: boolean;
    isShowMassDeleteFromStringButton?: boolean;
    keyField?: string;
    defaultSortField?: string;
    extraFilters?: Filter[];
}

const ItemsListTable: React.FC<IItemsListTableProps> = ({
    keyField = 'id',
    listColumns,
    title,
    listName,
    isShowCreateButton,
    isShowMassCreateFromStringButton,
    isShowMassDeleteFromStringButton,
    extraFilters,
    defaultSortField,
}) => {
    const {
        list,
        handleTableChange,
        handleFilterClose,
        handleCloseAllFilters,
        handleFilterChange,
        listParams,
        reloadList,
        updateItemAndReloadList,
    } = useList(listName, keyField, defaultSortField);

    const columns = useColumns({ columns: listColumns, item: listName, reloadList, updateItemAndReloadList, keyField });

    const pagination = getPagination({
        sizePerPage: listParams.sizePerPage,
        total: list.total,
        currentPage: listParams.page,
    });

    return (
        <React.Fragment>
            <Container className="mt--6" fluid={true}>
                <Header title={title} />
                <Row>
                    <Col>
                        <Card>
                            <div className="py-4 table-responsive bs-table-overflow">
                                <TableToolkit
                                    isShowMassCreateFromStringButton={isShowMassCreateFromStringButton}
                                    isShowMassDeleteFromStringButton={isShowMassDeleteFromStringButton}
                                    isShowCreateButton={isShowCreateButton}
                                    itemName={listName}
                                    onFilterClose={handleFilterClose}
                                    onCloseAllFilters={handleCloseAllFilters}
                                    onFilterChange={handleFilterChange}
                                    listColumns={listColumns}
                                    extraFilters={extraFilters}
                                />
                                <BootstrapTable
                                    data={list.data || []}
                                    columns={columns}
                                    keyField={keyField}
                                    bootstrap4={true}
                                    pagination={pagination}
                                    bordered={false}
                                    id="react-bs-table"
                                    remote={true}
                                    cellEdit={cellEditFactory({ mode: 'click' })}
                                    onTableChange={handleTableChange}
                                    defaultSorted={[
                                        { dataField: listParams.sortField, order: listParams.sortOrder || 'desc' },
                                    ]}
                                />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Notification />
        </React.Fragment>
    );
};

export default ItemsListTable;
