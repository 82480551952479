import React from 'react';
import { isActionColumn, ListColumn, ListColumnType } from 'components/tables/ItemsListTable/types';

import { ColumnDescription } from 'react-bootstrap-table-next';
import TableTextTooltipField from 'components/fields/TableTextTooltipField';
import formatDate from 'lib/formatDate';
import { formats } from 'lib/config';
import ItemActionButtons from 'components/buttons/ItemActionButtons';
import { generateRequestsByItemName } from 'api';
import { CellEditTextInput } from 'components/cell-edits/CellEditTextInput';
import { CellEditDateTimeInput } from 'components/cell-edits/CellEditDateTimeInput';
import TableBooleanField from 'components/fields/TableBooleanField';
import CellEditBooleanField from 'components/cell-edits/CellEditBooleanField';
import CellEditSelectField from 'components/cell-edits/CellEditSelectField';
import { generateIntegerSelectOptions } from 'lib/select';
import TableTextFromDictionary from '../fields/TableTextFromDictionary';

const DEFAULT_COLUMN_SIZE_EM = 5;

interface UseColumnsParams {
    columns: ListColumn[];
    parentField?: string;
    item?: string;
    reloadList?: (params?: { isDeleted: boolean }) => void;
    updateItemAndReloadList?: (params: { rowId: number | string; dataField: string; newValue: any }) => Promise<void>;
    keyField?: string;
}
export function useColumns({
    columns,
    parentField = '',
    item,
    reloadList,
    updateItemAndReloadList,
    keyField = 'id',
}: UseColumnsParams): ColumnDescription[] {
    const { deleteItem = null } = item ? generateRequestsByItemName(item) : {};

    const handleDelete = async (params: { id: number | string } | Record<string, any>) => {
        if (deleteItem) {
            await deleteItem(params);
        }
        if (reloadList) {
            reloadList({ isDeleted: true });
        }
    };

    return columns.map((listColumn) => {
        if (isActionColumn(listColumn)) {
            return {
                dataField: '',
                text: 'Действия',
                sort: false,
                formatter: (text, row) => (
                    <ItemActionButtons
                        row={row}
                        key={row[keyField]}
                        id={row[keyField]}
                        sendExtraFields={listColumn.sendExtraFields}
                        isEdit={listColumn.isEditButton}
                        isRemove={listColumn.isRemoveButton}
                        isRemoveWithModal={listColumn.isRemoveButtonWithModal}
                        isShow={listColumn.isFullShowButton}
                        onRemove={handleDelete}
                        extraButtons={
                            listColumn.extraActionButtons &&
                            listColumn.extraActionButtons(row, {
                                reloadList,
                                deleteItem,
                            })
                        }
                    />
                ),
                headerStyle: {
                    width: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                },
                style: {
                    verticalAlign: 'middle',
                },
                editable: false,
            };
        }

        if (
            listColumn.type === ListColumnType.TEXT_WITH_TOOLTIP ||
            listColumn.type === ListColumnType.TEXT ||
            listColumn.type === ListColumnType.TEXT_ENUM_FILTERED
        ) {
            return {
                dataField: listColumn.fieldName,
                text: listColumn.title,
                sort: listColumn.isSortable,
                formatter: (text, row, i) => (
                    <TableTextTooltipField
                        key={listColumn.fieldName}
                        spanId={`${parentField}${listColumn.fieldName}${i}`}
                        text={text}
                        isShowTooltip={listColumn.type === ListColumnType.TEXT_WITH_TOOLTIP}
                    />
                ),
                style: {
                    textOverflow: 'ellipsis',
                    minWidth: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                    maxWidth: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    verticalAlign: 'middle',
                },
                headerStyle: {
                    width: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                },
                editable: false,
            };
        }

        if (
            listColumn.type === ListColumnType.TEXT_FROM_DICTIONARY ||
            listColumn.type === ListColumnType.TEXT_FROM_DICTIONARY_TOOLTIP
        ) {
            return {
                dataField: listColumn.fieldName,
                text: listColumn.title,
                sort: listColumn.isSortable,
                formatter: (text, row, i) => (
                    <TableTextFromDictionary
                        dictionary={listColumn.dictionary}
                        spanId={`${parentField}${listColumn.fieldName}${i}`}
                        value={text}
                        fieldName={listColumn.fieldName}
                        isShowTooltip={listColumn.type === ListColumnType.TEXT_FROM_DICTIONARY_TOOLTIP}
                    />
                ),
                style: {
                    textOverflow: 'ellipsis',
                    minWidth: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                    maxWidth: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    verticalAlign: 'middle',
                },
                headerStyle: {
                    width: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                },
                editable: false,
            };
        }

        if (
            listColumn.type === ListColumnType.TEXT_WITH_TOOLTIP_EDITABLE ||
            listColumn.type === ListColumnType.TEXT_EDITABLE
        ) {
            return {
                dataField: listColumn.fieldName,
                text: listColumn.title,
                sort: listColumn.isSortable,
                formatter: (text, row, i) => (
                    <TableTextTooltipField
                        key={listColumn.fieldName}
                        spanId={`${parentField}${listColumn.fieldName}${i}`}
                        text={text}
                        isShowTooltip={listColumn.type === ListColumnType.TEXT_WITH_TOOLTIP_EDITABLE}
                    />
                ),
                editorRenderer: (editorProps, value, row) => (
                    <CellEditTextInput
                        id={row[keyField]}
                        onBlur={editorProps.onBlur}
                        onUpdate={editorProps.onUpdate}
                        value={value}
                    />
                ),
                style: {
                    textOverflow: 'ellipsis',
                    minWidth: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                    maxWidth: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    verticalAlign: 'middle',
                    cursor: 'pointer',
                },
                headerStyle: {
                    width: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                },
                editable: true,
            };
        }

        if (listColumn.type === ListColumnType.DATE_TIME) {
            return {
                dataField: listColumn.fieldName,
                text: listColumn.title,
                sort: listColumn.isSortable,
                formatter: (date, row, i) => (
                    <span key={`${listColumn.fieldName}${i}`}>{formatDate(new Date(date), formats.dateTime)}</span>
                ),
                style: {
                    minWidth: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                    maxWidth: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                    verticalAlign: 'middle',
                },
                headerStyle: {
                    width: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                },
                editable: false,
            };
        }

        if (listColumn.type === ListColumnType.DATE) {
            return {
                dataField: listColumn.fieldName,
                text: listColumn.title,
                sort: listColumn.isSortable,
                formatter: (date, row, i) => (
                    <span key={`${listColumn.fieldName}${i}`}>{formatDate(new Date(date), formats.date)}</span>
                ),
                style: {
                    minWidth: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                    maxWidth: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                    verticalAlign: 'middle',
                },
                headerStyle: {
                    width: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                },
                editable: false,
            };
        }

        if (listColumn.type === ListColumnType.DATE_TIME_EDITABLE) {
            return {
                dataField: listColumn.fieldName,
                text: listColumn.title,
                sort: listColumn.isSortable,
                formatter: (date, row, i) => (
                    <span key={`${listColumn.fieldName}${i}`}>{formatDate(new Date(date), formats.dateTime)}</span>
                ),
                editorRenderer: (editorProps, value, row) => (
                    <CellEditDateTimeInput
                        id={row[keyField]}
                        onBlur={editorProps.onBlur}
                        onUpdate={editorProps.onUpdate}
                        value={value}
                    />
                ),
                style: {
                    minWidth: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                    maxWidth: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                    verticalAlign: 'middle',
                    cursor: 'pointer',
                },
                headerStyle: {
                    width: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                },
                editable: true,
            };
        }

        if (listColumn.type === ListColumnType.BOOLEAN) {
            return {
                dataField: listColumn.fieldName,
                text: listColumn.title,
                sort: listColumn.isSortable,
                formatter: (isEnabled, row, i) => (
                    <TableBooleanField
                        spanId={`${parentField}${listColumn.fieldName}${i}`}
                        key={`${listColumn.fieldName}${i}`}
                        isEnabled={isEnabled}
                    />
                ),
                style: {
                    minWidth: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                    maxWidth: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                    verticalAlign: 'middle',
                },
                headerStyle: {
                    width: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                },
                editable: false,
            };
        }

        if (listColumn.type === ListColumnType.BOOLEAN_EDITABLE) {
            return {
                dataField: listColumn.fieldName,
                text: listColumn.title,
                sort: listColumn.isSortable,
                formatter: (isEnabled, row, i) => (
                    <CellEditBooleanField
                        onChange={() =>
                            updateItemAndReloadList &&
                            updateItemAndReloadList({
                                rowId: row[keyField],
                                newValue: !isEnabled,
                                dataField: listColumn.fieldName,
                            })
                        }
                        spanId={`${parentField}${listColumn.fieldName}${i}`}
                        key={`${listColumn.fieldName}${i}`}
                        isEnabled={isEnabled}
                    />
                ),
                style: {
                    minWidth: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                    maxWidth: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                    verticalAlign: 'middle',
                },
                headerStyle: {
                    width: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                },
                editable: false,
            };
        }

        if (listColumn.type === ListColumnType.SELECT) {
            return {
                dataField: listColumn.fieldName,
                text: listColumn.title,
                sort: listColumn.isSortable,
                formatter: (value, row, i) => (
                    <CellEditSelectField
                        onChange={(newValue) =>
                            updateItemAndReloadList &&
                            updateItemAndReloadList({
                                rowId: row[keyField],
                                newValue,
                                dataField: listColumn.fieldName,
                            })
                        }
                        spanId={`${parentField}${listColumn.fieldName}${i}`}
                        key={`${listColumn.fieldName}${i}`}
                        selectOptions={
                            listColumn.integerSelectOptions
                                ? generateIntegerSelectOptions(listColumn.integerSelectOptions)
                                : listColumn.selectOptions
                        }
                        value={value}
                    />
                ),
                style: {
                    minWidth: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                    maxWidth: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                    verticalAlign: 'middle',
                },
                headerStyle: {
                    width: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                },
                editable: false,
            };
        }

        return {
            dataField: listColumn.fieldName,
            text: listColumn.title,
            sort: listColumn.isSortable,
            style: {
                minWidth: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                maxWidth: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
                verticalAlign: 'middle',
            },
            headerStyle: {
                width: `${listColumn.size || DEFAULT_COLUMN_SIZE_EM}em`,
            },
            editable: false,
        };
    });
}
