import React, { useCallback } from 'react';
import { Control, useController, UseControllerProps } from 'react-hook-form';
import { convertStringOrNullOrNumber, getTitleWithAsterisk } from 'lib/text';
import { getErrorByName } from 'lib/form';
import { FormError } from 'components/forms/FormError';
import { StyledFormSelect } from 'components/form-inputs/styles';
import { useHiddenDisabled } from './useHiddenDisabled';

interface FormTextInputProps {
    label: string;
    name: UseControllerProps['name'];
    rules?: UseControllerProps['rules'];
    control: Control<any>;
    defaultValue?: string | null;
    selectOptions?: Array<{ selectValue: any; title: any }>;
    isDisabledProp?: boolean;
    isHiddenProp?: boolean;
}

export const FormSelectInput: React.FC<FormTextInputProps> = ({
    label,
    rules,
    control,
    name,
    defaultValue,
    selectOptions,
    isDisabledProp,
    isHiddenProp,
}) => {
    const {
        field,
        formState: { errors },
    } = useController({
        rules,
        control,
        name,
        defaultValue: defaultValue || defaultValue === null ? defaultValue : '',
    });
    const { isHidden, isDisabled } = useHiddenDisabled({ isDisabledProp, isHiddenProp });

    const error = getErrorByName(name, errors);

    const handleChange = useCallback(
        (text: string) => {
            field.onChange(convertStringOrNullOrNumber(text));
        },
        [field],
    );

    if (isHidden) {
        return null;
    }

    return (
        <div className="align-items-center">
            <label className="form-control-label">{getTitleWithAsterisk(label, !!rules?.required)}</label>
            <StyledFormSelect
                name={field.name}
                onBlur={field.onBlur}
                ref={field.ref}
                value={String(field.value)}
                onChange={(e) => handleChange(e.target.value)}
                className="form-control"
                disabled={isDisabled}
            >
                {selectOptions &&
                    selectOptions.map(({ selectValue, title }) => (
                        <option key={String(selectValue)} value={selectValue}>
                            {String(title)}
                        </option>
                    ))}
            </StyledFormSelect>
            <FormError error={error} />
        </div>
    );
};
