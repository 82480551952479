import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { Control, useController, UseControllerProps } from 'react-hook-form';
import { getTitleWithAsterisk } from 'lib/text';
import { getErrorByName } from 'lib/form';
import { FormError } from 'components/forms/FormError';
import { useDictionary } from 'hooks/useDictionary';
import { DictionaryParams } from 'types/dictionary';
import { observer } from 'mobx-react-lite';
import {
    getSelectValueByFieldValue,
    getFieldValueFromSelected,
    getDictionaryDefaultValueByFieldType,
} from 'lib/dictionary';
import FormSelect from '../react-select/FormSelect';
import { SendFieldsFromDictionary } from '../forms/input.types';
import { useHiddenDisabled } from './useHiddenDisabled';

interface FormTextInputProps {
    label: string;
    name: UseControllerProps['name'];
    rules?: UseControllerProps['rules'];
    control: Control<any>;
    defaultValue?: string | number | string[] | number[] | null | undefined;
    sendFieldsFromDictionary?: SendFieldsFromDictionary;
    dictionary: DictionaryParams;
    isMulti?: boolean;
    isDisabledProp?: boolean;
    isHiddenProp?: boolean;
}

const FormDictionarySelect: React.FC<FormTextInputProps> = observer(
    ({
        label,
        rules,
        control,
        name,
        defaultValue,
        dictionary,
        isMulti,
        sendFieldsFromDictionary = SendFieldsFromDictionary.ID,
        isDisabledProp,
        isHiddenProp,
    }) => {
        const dictionaryItems = useDictionary(dictionary);

        const {
            field,
            formState: { errors },
        } = useController({
            rules,
            control,
            name,
        });
        const { isHidden, isDisabled } = useHiddenDisabled({ isDisabledProp, isHiddenProp });

        const fieldOnChange = field.onChange;

        const [selectorValue, setSelectorValue] = useState();

        useEffect(() => {
            setSelectorValue((prevValue) => {
                const newValue = getSelectValueByFieldValue(field.value, dictionaryItems);
                if (!isEqual(prevValue, newValue)) {
                    return newValue;
                }

                return prevValue;
            });
        }, [dictionaryItems, field.value]);

        useEffect(() => {
            if (defaultValue && dictionaryItems && dictionaryItems.length) {
                const fieldValue = getDictionaryDefaultValueByFieldType(
                    defaultValue,
                    sendFieldsFromDictionary,
                    dictionaryItems,
                );
                fieldOnChange(fieldValue);
            }
        }, [dictionaryItems, defaultValue, fieldOnChange, sendFieldsFromDictionary]);
        const handleChange = (selected) => {
            if (isMulti) {
                const selectedValues = selected.map((el) => getFieldValueFromSelected(el, sendFieldsFromDictionary));
                fieldOnChange(selectedValues);
            } else {
                fieldOnChange(getFieldValueFromSelected(selected, sendFieldsFromDictionary));
            }
        };

        const error = getErrorByName(name, errors);

        if (isHidden) {
            return null;
        }

        return (
            <div className="align-items-center">
                <label className="form-control-label">{getTitleWithAsterisk(label, !!rules?.required)}</label>
                <FormSelect
                    onChange={handleChange}
                    options={dictionaryItems.map((el) => ({ value: el.id, label: el.title }))}
                    forwardedRef={field.ref}
                    onBlur={field.onBlur}
                    isMulti={isMulti}
                    value={selectorValue}
                    disabled={isDisabled}
                    defaultValue={getSelectValueByFieldValue(defaultValue, dictionaryItems)}
                />
                <FormError error={error} />
            </div>
        );
    },
);

export default FormDictionarySelect;
