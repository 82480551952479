import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStore } from './store';

import { Router } from './Router';
import Loader from './components/Loader';

export const App: React.FC = observer(() => {
    const authStore = useStore('auth');
    const history = useHistory();

    useEffect(() => {
        if (!authStore.isLoggedIn && !authStore.inProgress) {
            authStore.loginByToken();
        }
        if (!authStore.isLoggedIn && authStore.isLoginTryDone) {
            history.push('/auth/login');
        }
    }, [authStore.isLoggedIn]);

    if (!authStore.isLoggedIn && !authStore.isLoginTryDone) {
        return <Loader />;
    }

    return <Router />;
});
