import { ListColumn } from 'components/tables/ItemsListTable/types';

interface ItemFieldParams {
    fieldName: string;
    title: string;
    size?: number;
    // Параметр определяет, что в форме редактирования поле будет отрисовываться с использованием mapViewFields
    // в src/components/forms/ViewItemForm/ViewItemFormRow.tsx
    isEditFormViewField?: boolean;
}

interface ItemTextField extends ItemFieldParams {
    type: ItemFieldType.TEXT | ItemFieldType.TEXT_WITH_TOOLTIP;
}

interface ItemDateField extends ItemFieldParams {
    type: ItemFieldType.DATE;
    format?: string;
}

interface ItemTableField extends ItemFieldParams {
    type: ItemFieldType.TABLE;
    listColumns: ListColumn[];
}

export type ItemField = ItemTextField | ItemDateField | ItemTableField;

export type ItemFieldTable = ItemField[][];

export enum ItemFieldType {
    TEXT = 'TEXT',
    TEXT_WITH_TOOLTIP = 'TEXT_WITH_TOOLTIP',
    DATE = 'DATE',
    TABLE = 'TABLE',
}
