import React from 'react';
import 'assets/css/tooltip.css';
import { UncontrolledTooltip } from 'reactstrap';

interface CellEditBooleanFieldProps {
    isEnabled: boolean;
    spanId: string;
    onChange: () => void;
}

const CellEditBooleanField: React.FC<CellEditBooleanFieldProps> = ({ isEnabled, spanId, onChange }) => {
    const spanUniqId = `table-boolean-span-id-${spanId}`;

    return (
        <div className="align-items-center">
            <label className="custom-toggle-table custom-toggle-table-table-dark">
                <input onInput={onChange} defaultChecked={isEnabled} type="checkbox" />
                <span id={spanUniqId} className="custom-toggle-table-slider rounded-circle" />
            </label>
            <UncontrolledTooltip popperClassName="tooltip-custom" placement="bottom-start" target={spanUniqId}>
                {String(isEnabled)}
            </UncontrolledTooltip>
        </div>
    );
};

export default CellEditBooleanField;
