import React from 'react';
import { Row, Col } from 'reactstrap';

interface FormButtonContainerProps {
    leftSideButtons?: React.ReactNode[];
    rightSideButtons?: React.ReactNode[];
    className?: string;
}

export const FormButtonContainer: React.FC<FormButtonContainerProps> = ({
    leftSideButtons,
    rightSideButtons,
    className = 'mt-5',
}) => {
    if (!leftSideButtons?.length && !rightSideButtons?.length) {
        return null;
    }

    return (
        <Row className={className}>
            <Col style={{ display: 'flex', justifyContent: 'start' }}>{leftSideButtons}</Col>
            <Col style={{ display: 'flex', justifyContent: 'end' }}>{rightSideButtons}</Col>
        </Row>
    );
};
