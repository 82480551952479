import React from 'react';
import formatDate from 'lib/formatDate';
import { StyledDisabledInput, StyledInput } from 'components/fields/styles';

interface ViewDateFieldProps {
    title: string;
    value: string | Date;
    format?: string;
    isDisabled?: boolean;
}

export const ViewDateField: React.FC<ViewDateFieldProps> = ({ title, value, format, isDisabled }) => {
    const text = formatDate(new Date(value), format);

    return (
        <div className="align-items-center">
            <label className="form-control-label">{title.toUpperCase()}</label>
            {isDisabled && (
                <StyledDisabledInput readOnly={true} id={`span-id-${title}`} type="text" defaultValue={text} />
            )}
            {!isDisabled && <StyledInput readOnly={true} id={`span-id-${title}`} type="text" defaultValue={text} />}
        </div>
    );
};
