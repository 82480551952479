import React from 'react';

import ItemsListTable from 'components/tables/ItemsListTable';
import { ListColumn, ListColumnType } from 'components/tables/ItemsListTable/types';
import { Item } from 'types/item.enum';
import { FilterInputType } from 'components/filters/types';
import { AdminDictionaryId, DictionaryType } from '../../../types/dictionary';

const columns: ListColumn[] = [
    {
        title: 'Действия',
        type: ListColumnType.ACTIONS,
        isEditButton: true,
        isRemoveButtonWithModal: true,
    },
    {
        fieldName: 'id',
        title: 'ID',
        size: 5,
        type: ListColumnType.TEXT,
        isSortable: true,
    },
    {
        fieldName: 'title',
        title: 'Название',
        size: 10,
        type: ListColumnType.TEXT,
        showFilter: true,
    },
    {
        fieldName: 'sortOrder',
        title: 'Порядок',
        size: 10,
        type: ListColumnType.TEXT_EDITABLE,
        isSortable: true,
    },
    {
        fieldName: 'isEnabled',
        title: 'Показывать?',
        size: 5,
        type: ListColumnType.BOOLEAN_EDITABLE,
    },
];

const View: React.FC = () => {
    return (
        <ItemsListTable
            extraFilters={[
                {
                    name: 'Страховая компания',
                    title: 'insuranceCompanyId',
                    isEnabled: false,
                    type: FilterInputType.DICTIONARY,
                    dictionary: {
                        [DictionaryType.ADMIN]: AdminDictionaryId.INSURANCE_COMPANY,
                    },
                },
            ]}
            isShowCreateButton={true}
            title="Список банков"
            listName={Item.BANK}
            listColumns={columns}
        />
    );
};

export default View;
