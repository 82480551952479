import React from 'react';
import { useHistory, Link } from 'react-router-dom';

import TableRowActionIcon from 'components/icons/ActionIcon';
import TableRemoveButton from 'components/buttons/TableRemoveButton';
import TableRemoveButtonWithModal from 'components/buttons/TableRemoveButtonWithModal';
import TableEditButton from './TableEditButton';
import { jsonToQueryString } from '../../lib/parseUrl';

interface ItemActionButtonsProps {
    path?: string;
    row: Record<string, any>;
    id: number | string;
    isEdit?: boolean;
    onRemove?: (params: { id: number | string }) => void;
    isRemove?: boolean;
    isRemoveWithModal?: boolean;
    isShow?: boolean;
    sendExtraFields?: string[];
    extraButtons?: React.ReactNode[];
}

function getExtraValues(row: Record<string, any>, sendExtraFields?: string[]) {
    if (!sendExtraFields) {
        return {};
    }

    return Object.fromEntries(Object.entries(row).filter(([k]) => sendExtraFields?.includes(k)));
}

const ItemActionButtons: React.FC<ItemActionButtonsProps> = ({
    id,
    row,
    isShow,
    isEdit,
    onRemove,
    isRemove,
    isRemoveWithModal,
    sendExtraFields,
    extraButtons,
}) => {
    const { location } = useHistory();
    const sendExtraValues = getExtraValues(row, sendExtraFields);

    const path = location.pathname.split('/').pop();

    return (
        <div style={{ textAlign: 'center', lineHeight: 'normal' }}>
            {isShow && (
                <Link to={`${path}/${id}?`}>
                    <TableRowActionIcon title="Просмотр" className="fa fa-bars" />
                </Link>
            )}
            {isEdit && (
                <Link to={`${path}/${id}/edit${jsonToQueryString(sendExtraValues)}`}>
                    <TableEditButton />
                </Link>
            )}
            {extraButtons}
            {isRemove && onRemove && <TableRemoveButton onClick={() => onRemove({ id, ...sendExtraValues })} />}
            {isRemoveWithModal && onRemove && (
                <TableRemoveButtonWithModal id={id} onRemove={() => onRemove({ id, ...sendExtraValues })} />
            )}
        </div>
    );
};

export default ItemActionButtons;
