import styled from 'styled-components';
import { FormGroup, DropdownToggle } from 'reactstrap';

export const FromGroupStyled = styled(FormGroup)`
    margin-bottom: 1em;
    min-width: 11.5em;
    display: flex;
    flex-direction: column;
`;

export const FilterDropdownStyled = styled(DropdownToggle)`
    background-color: #fff !important;
    &:focus {
        border: 1px solid #dee2e6;
        box-shadow: none;
    }
    padding: 4px 8px 4px 8px;
    :8px ;
    height: 2.3em;
    font-style: normal;
    font-weight: 400;
    color: #8898aa;
    max-width: 14em;
    min-width: 11.5em;
    overflow: scroll;
    overflow-wrap: anywhere;
    text-align: center;
`;
