import React from 'react';

import { Item } from 'types/item.enum';
import { InputItemFormRow } from 'components/forms/EditItemForm/InputItemFormRow';
import { FormButtonContainer } from 'components/forms/FormButtonContainer';
import { BackButton } from 'components/buttons/BackButton';
import { ItemFormCard } from 'components/forms/ItemFormCard';
import { NoShadowButton } from 'components/buttons/styles';
import Notification from 'components/notifications/Notification';
import { ItemFieldEditForm } from '../input.types';
import { useEditForm } from './useEditForm';

interface EditItemFormProps {
    fields: ItemFieldEditForm;
    itemName: Item;
    title: string;
    defaultValues: Record<string, any>;
    item: Record<string, any>;
    sendSearchData?: boolean;
}

const Form: React.FC<EditItemFormProps> = ({ fields, title, itemName, defaultValues, item, sendSearchData }) => {
    const backPath = `/admin/${itemName}`;

    const { handleFormSubmit, control } = useEditForm({ itemName, backPath, defaultValues, sendSearchData });

    return (
        <ItemFormCard title={`${title}`}>
            {fields.map((cells) => (
                <InputItemFormRow
                    item={item}
                    control={control}
                    key={cells.map((el) => el.fieldName).join('.')}
                    cells={cells}
                />
            ))}
            <FormButtonContainer
                leftSideButtons={[<BackButton key="back" title="Назад" to={backPath} />]}
                rightSideButtons={[
                    <NoShadowButton onClick={handleFormSubmit} type="submit" key="send" size="sm">
                        Отправить
                    </NoShadowButton>,
                ]}
            />
            <Notification />
        </ItemFormCard>
    );
};

export default Form;
