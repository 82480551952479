const cash: { [key: string]: Array<{ selectValue: any; title: any }> } = {};

export function generateIntegerSelectOptions(params: { minValue?: number; maxValue: number; nullable?: boolean }) {
    const { minValue = 0, maxValue, nullable = false } = params;

    const cashKey = `${minValue}${maxValue}${nullable}`;

    if (cash[cashKey]) {
        return cash[cashKey];
    }

    const selectOptions: Array<{ selectValue: any; title: any }> = [];

    for (let i = minValue; i <= maxValue; i++) {
        selectOptions.push({ selectValue: i, title: String(i) });
    }

    if (nullable) {
        selectOptions.push({ selectValue: null, title: 'null' });
    }

    cash[cashKey] = selectOptions;

    return selectOptions;
}
