import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { preparePlainText } from 'lib/text';
import { StyledDisabledInput, StyledInput } from 'components/fields/styles';

interface ViewTextFieldProps {
    title: string;
    id: string;
    value: any;
    isShowTooltip?: boolean;
    isDisabled?: boolean;
}

export const ViewTextField: React.FC<ViewTextFieldProps> = ({ id, title, value, isShowTooltip, isDisabled }) => {
    const spanUniqId = `span-id-${id}`;

    return (
        <React.Fragment>
            <label className="form-control-label">{title.toUpperCase()}</label>
            {isDisabled && (
                <StyledDisabledInput
                    id={spanUniqId}
                    type="text"
                    readOnly={true}
                    defaultValue={preparePlainText(value)}
                />
            )}
            {!isDisabled && (
                <StyledInput id={spanUniqId} type="text" readOnly={true} defaultValue={preparePlainText(value)} />
            )}
            {isShowTooltip && (
                <UncontrolledTooltip popperClassName="tooltip-custom" placement="bottom-start" target={spanUniqId}>
                    {preparePlainText(value)}
                </UncontrolledTooltip>
            )}
        </React.Fragment>
    );
};
