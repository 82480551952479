import React from 'react';

import TableRowActionIcon from 'components/icons/ActionIcon';

interface TableDeleteButtonProps {
    onClick: () => void;
}

const TableRemoveButton: React.FC<TableDeleteButtonProps> = ({ onClick }) => {
    return (
        <TableRowActionIcon onClick={() => onClick()} activeColor="#f5365c" title="Удалить" className="fa fa-trash" />
    );
};

export default TableRemoveButton;
