import styled from 'styled-components';
import { Input } from 'reactstrap';

export const StyledInput = styled(Input)`
    background-color: #fff !important;
    &:focus {
        border: 1px solid #dee2e6;
        box-shadow: none;
    }
    input[type='number'] {
        -webkit-appearance: none !important;
        margin: 0 !important;
        -moz-appearance: textfield !important;
    }
`;

export const StyledDisabledInput = styled(Input)`
    background-color: #f3f3f3 !important;

    &:focus {
        border: 1px solid #dee2e6;
        box-shadow: none;
    }
`;
