import styled from 'styled-components';

export const FiltersContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
`;

export const FilterElement = styled.div`
    flex-basis: min-content;
    flex-grow: 0;
    width: 100%;
    align-self: center;
    margin-left: 1em;
`;

export const FilterElementContainer = styled.div`
    flex-wrap: nowrap;
    display: flex;
    flex-direction: row;
`;

export const FilterTitle = styled.label`
    font-size: 0.7em;
    margin-bottom: 0;
`;
