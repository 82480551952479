import React, { useEffect, useState } from 'react';
import { useDictionary } from 'hooks/useDictionary';
import { DictionaryParams } from 'types/dictionary';
import { observer } from 'mobx-react-lite';
import TableTextTooltipField from './TableTextTooltipField';

interface TableTextFromDictionaryProps {
    spanId: string;
    value: string | number;
    isShowTooltip?: boolean;
    fieldName: string;
    dictionary: DictionaryParams;
}

const TableTextFromDictionary: React.FC<TableTextFromDictionaryProps> = observer(
    ({ spanId, isShowTooltip, value, dictionary, fieldName }) => {
        const dictionaryItems = useDictionary(dictionary);

        const [text, setText] = useState<string>('');

        useEffect(() => {
            const textValue = dictionaryItems.find((el) => String(el.id) === String(value))?.title;
            if (textValue) {
                setText(textValue);
            } else {
                setText(String(value));
            }
        }, [dictionaryItems, value]);

        return <TableTextTooltipField key={fieldName} spanId={spanId} text={text} isShowTooltip={isShowTooltip} />;
    },
);

export default TableTextFromDictionary;
