import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import CloseButton from 'components/buttons/CloseButton';
import { Filter } from 'components/filters/types';
import { FilterElementContainer, FilterElement } from 'elements/filters';

interface FilterSelectorProps {
    onClickCloseAll: () => void;
    filters: Filter[];
    onFilterToggle: (filterName: string) => void;
}

const FilterSelector: React.FC<FilterSelectorProps> = ({ onClickCloseAll, filters, onFilterToggle }) => {
    const enabledFilters = filters.filter((el) => el.isEnabled && !el.disableClose);

    return (
        <FilterElement>
            <FilterElementContainer>
                {enabledFilters.length ? (
                    <CloseButton title="Очистить все фильтры" isHidden={false} handleClose={onClickCloseAll} />
                ) : null}
                <UncontrolledDropdown group={true}>
                    <DropdownToggle size="sm" caret={true} color="secondary">
                        Добавить фильтр
                    </DropdownToggle>
                    <DropdownMenu>
                        {filters
                            .filter((el) => !el.disableClose)
                            .map((filter) => (
                                <DropdownItem
                                    key={filter.name}
                                    disabled={false}
                                    onClick={() => {
                                        if (!filter.disableClose) {
                                            onFilterToggle(filter.name);
                                        }
                                    }}
                                >
                                    {filter.title.toUpperCase()}
                                </DropdownItem>
                            ))}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </FilterElementContainer>
        </FilterElement>
    );
};

export default FilterSelector;
