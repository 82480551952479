import React from 'react';

import { Item } from 'types/item.enum';
import { InputItemFormRow } from 'components/forms/CreateItemForm/InputItemFormRow';
import { FormButtonContainer } from 'components/forms/FormButtonContainer';
import { BackButton } from 'components/buttons/BackButton';
import { ItemFormCard } from 'components/forms/ItemFormCard';
import { ItemFieldCreateForm } from 'components/forms/input.types';
import { NoShadowButton } from 'components/buttons/styles';
import { useCreateForm } from 'components/forms/CreateItemForm/useCreateForm';
import Notification from 'components/notifications/Notification';

interface CreateItemFormProps {
    fields: ItemFieldCreateForm;
    itemName: Item;
    title: string;
    data?: Record<any, any>;
}

export const CreateItemForm: React.FC<CreateItemFormProps> = ({ fields, title, itemName }) => {
    const backPath = `/admin/${itemName}`;

    const { handleFormSubmit, control } = useCreateForm({ itemName, backPath });

    return (
        <ItemFormCard title={`${title}`}>
            {fields.map((cells) => (
                <InputItemFormRow control={control} key={cells.map((el) => el.fieldName).join('.')} cells={cells} />
            ))}
            <FormButtonContainer
                leftSideButtons={[<BackButton key="back" title="Назад" to={backPath} />]}
                rightSideButtons={[
                    <NoShadowButton onClick={handleFormSubmit} type="submit" key="send" size="sm">
                        Отправить
                    </NoShadowButton>,
                ]}
            />
            <Notification />
        </ItemFormCard>
    );
};
