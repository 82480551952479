import styled from 'styled-components';

const StyledI = styled.i<{ isLoading?: boolean; activeColor?: string; fontSize?: string }>`
    color: ${({ isLoading }) => (isLoading ? '#f7fafc' : '#8898aa')};
    cursor: pointer;
    font-size: ${({ fontSize }) => fontSize || '1em'};
    :hover {
        color: ${({ activeColor }) => activeColor || '#2dce89'};
    }
    margin-left: 0.5em;
    margin-right: 0.5em;
`;

export default StyledI;
