import React from 'react';
import { useLocation, Redirect, RouteComponentProps, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { useStore } from 'store';
import routes, { getRoutes } from 'routes';
import Notification from 'components/notifications/Notification';

const AuthLayout: React.FC<RouteComponentProps> = observer(() => {
    const location = useLocation();
    const mainContentRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement) {
            document.scrollingElement.scrollTop = 0;
        }
        if (mainContentRef.current) {
            mainContentRef.current.scrollTop = 0;
        }
        document.body.classList.add('bg-default');

        return function cleanup() {
            document.body.classList.remove('bg-default');
        };
    });
    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        if (document.scrollingElement) {
            document.scrollingElement.scrollTop = 0;
        }
        if (mainContentRef.current) {
            mainContentRef.current.scrollTop = 0;
        }
    }, [location]);
    const authStore = useStore('auth');

    const history = useHistory();

    if (authStore.isLoggedIn) {
        history.push('/admin');

        return null;
    }

    return (
        <React.Fragment>
            <div className="main-content" ref={mainContentRef}>
                {getRoutes(routes, '/auth')}
                <Redirect from="*" to="/auth/login" />
            </div>
            <Notification />
        </React.Fragment>
    );
});

export default AuthLayout;
