import React, { useState, useEffect } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import { endOfDay } from 'date-fns';

import CloseButton from 'components/buttons/CloseButton';
import { FilterElementContainer, FilterElement, FilterTitle } from 'elements/filters';
import { IDatePeriod } from 'components/filters/types';
import { FromGroupStyled } from 'components/filters/components/styles';
import { StyledInput } from 'components/fields/styles';

registerLocale('ru', ru);

interface IDateSelect {
    onChange: (value: IDatePeriod) => void;
    handleClose: () => void;
    isHideCloseButton?: boolean;
    placeholder?: string;
    dateFormat?: string;
    label?: string;
    defaultValue?: any;
}

const DateSelect: React.FC<IDateSelect> = ({ handleClose, label, onChange, isHideCloseButton, defaultValue }) => {
    const [dateRange, setDateRange] = useState<{ startDate: Date | null; endDate: Date | null }>({
        startDate: null,
        endDate: null,
    });
    const { startDate, endDate } = dateRange;

    useEffect(() => {
        if (defaultValue?.from) {
            setDateRange((oldDateRange) => ({ ...oldDateRange, startDate: new Date(defaultValue.from) }));
        }
        if (defaultValue?.to) {
            setDateRange((oldDateRange) => ({ ...oldDateRange, endDate: new Date(defaultValue.to) }));
        }
    }, [defaultValue]);

    return (
        <FilterElement>
            <FilterElementContainer>
                <FromGroupStyled>
                    {label && <FilterTitle className="form-control-label">{label}</FilterTitle>}
                    <DatePicker
                        selectsRange={true}
                        locale="ru"
                        startDate={startDate}
                        endDate={endDate}
                        dateFormat="dd/MM/yyyy"
                        onChange={(newDate) => {
                            setDateRange({ startDate: newDate[0], endDate: newDate[1] });
                            if (newDate) {
                                onChange({
                                    ...(newDate[0] && { from: newDate[0].toISOString() }),
                                    ...(newDate[1] && { to: endOfDay(newDate[1]).toISOString() }),
                                });
                            }
                        }}
                        isClearable={false}
                        customInput={<StyledInput bsSize="sm" />}
                    />
                </FromGroupStyled>
                {!isHideCloseButton && <CloseButton handleClose={handleClose} />}
            </FilterElementContainer>
        </FilterElement>
    );
};
DateSelect.defaultProps = {
    dateFormat: 'YYYY-MM-DD',
    placeholder: 'Укажите дату',
};

export default DateSelect;
