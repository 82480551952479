import { useForm } from 'react-hook-form';
import { generateRequestsByItemName } from 'api';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useHistory } from 'react-router';

function excludeEmptyData(data) {
    return Object.fromEntries(
        Object.entries(data).filter(([, value]) => {
            if (value === undefined) {
                return false;
            }
            if (typeof value === 'string') {
                return !!value.length;
            }

            return true;
        }),
    );
}

export function useCreateForm({ itemName, backPath }: { itemName: string; backPath?: string }) {
    const { handleSubmit, control } = useForm();

    const { createItem } = generateRequestsByItemName(itemName);

    const { push } = useHistory();

    const handleFormSubmit = handleSubmit(async (data) => {
        const excludedEmptyData = excludeEmptyData(data);

        await createItem(excludedEmptyData);

        if (backPath) {
            push(backPath);
        }
    });

    return { control, handleFormSubmit };
}
