import React, { FocusEventHandler } from 'react';
import ReactSelect from 'react-select';
import { SelectValue } from './types';

interface FilterSelectorProps {
    onChange: (value: any) => void;
    options: Array<{ value: string | number; label: string }>;
    ref?: React.Ref<any>;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    defaultValue?: SelectValue;
}

const FilterSelect: React.FC<FilterSelectorProps> = ({ onChange, options, ref, onBlur, defaultValue }) => {
    return (
        <ReactSelect
            ref={ref}
            onBlur={onBlur}
            value={defaultValue}
            placeholder="Выбрать..."
            // @ts-ignore
            noOptionsMessage="Нет элементов для выбора"
            // @ts-ignore
            onChange={onChange}
            // @ts-ignore
            options={options}
            theme={(theme) => ({
                ...theme,
                border: 'none',
                colors: {
                    ...theme.colors,
                    primary25: '#ced4da',
                    primary: '#8898aa',
                },
            })}
            styles={{
                input: (styles) => ({ ...styles, margin: 'none' }),
                control: (styles) => ({
                    ...styles,
                    minHeight: '2em',
                    maxHeight: '2em',
                    border: '1px solid #dee2e6',
                    boxShadow: 'none',
                    ':hover': {
                        border: '1px solid #dee2e6',
                    },
                }),
                placeholder: (styles) => ({
                    ...styles,
                    fontWeight: 400,
                    color: '#8898aa',
                    fontSize: '0.9em',
                }),
                singleValue: (styles) => ({
                    ...styles,
                    fontWeight: 400,
                    color: '#8898aa',
                    fontSize: '0.9em',
                }),
                valueContainer: (styles) => ({ ...styles, minHeight: '2em', maxHeight: '2em' }),
                indicatorsContainer: (styles) => ({ ...styles, minHeight: '2em', maxHeight: '2em' }),
            }}
        />
    );
};

export default FilterSelect;
