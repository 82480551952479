import React, { useEffect } from 'react';
import { Redirect, Switch, useLocation, RouteComponentProps } from 'react-router-dom';
import Sidebar from 'components/Sidebar';
import AdminNavbar from 'components/navs/Navbar';
import AdminFooter from 'layouts/admin/AdminFooter';
import routes, { getRoutes } from 'routes';

const AdminLayout: React.FC<RouteComponentProps> = () => {
    const location = useLocation();
    const [sidenavOpen, setSidenavOpen] = React.useState(true);
    const mainContentRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        document.documentElement.scrollTop = 0;

        if (document.scrollingElement) {
            document.scrollingElement.scrollTop = 0;
        }

        if (mainContentRef.current) {
            mainContentRef.current.scrollTop = 0;
        }
    }, [location]);

    const toggleSidenav = () => {
        if (document.body.classList.contains('g-sidenav-pinned')) {
            document.body.classList.remove('g-sidenav-pinned');
            document.body.classList.add('g-sidenav-hidden');
        } else {
            document.body.classList.add('g-sidenav-pinned');
            document.body.classList.remove('g-sidenav-hidden');
        }
        setSidenavOpen(!sidenavOpen);
    };

    return (
        <React.Fragment>
            <Sidebar routes={routes} toggleSidenav={toggleSidenav} sidenavOpen={sidenavOpen} />
            <div className="main-content" ref={mainContentRef}>
                <AdminNavbar theme="light" toggleSidenav={toggleSidenav} sidenavOpen={sidenavOpen} />
                <div style={{ minHeight: 'calc(75vh)', marginBottom: '15vh', marginTop: '5em' }}>
                    <Switch>
                        {getRoutes(routes, '/admin')}
                        <Redirect from="/admin" to="/admin/dashboard" />
                    </Switch>
                </div>
                <AdminFooter />
            </div>

            {sidenavOpen ? <div className="backdrop d-xl-none" onClick={toggleSidenav} /> : null}
        </React.Fragment>
    );
};

export default AdminLayout;
