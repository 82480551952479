import React from 'react';
import { Modal } from 'reactstrap';
import { NoShadowButton } from 'components/buttons/styles';

interface ConfirmationModalProps {
    isOpen: boolean;
    toggleOpen: (isOpen: boolean | ((value: boolean) => boolean)) => void;
    callback: () => void;
    title: string;
    text: string;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ title, text, callback, toggleOpen, isOpen }) => {
    const handleSubmit = () => {
        toggleOpen(false);
        callback();
    };

    return (
        <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={() => toggleOpen((value) => !value)}>
            <div className="modal-header">
                <h5 className="modal-title" id="modalLabel">
                    {title}
                </h5>
                <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => toggleOpen(false)}
                >
                    <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">{text}</div>
            <div className="modal-footer">
                <NoShadowButton color="primary" data-dismiss="modal" type="button" onClick={() => toggleOpen(false)}>
                    Отмена
                </NoShadowButton>
                <NoShadowButton onClick={handleSubmit} className="ml-auto" color="success" type="button">
                    Подтвердить
                </NoShadowButton>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
