import { useMemo, useState, useEffect } from 'react';
import { Filter, FilterInputType } from 'components/filters/types';
import { ListColumn, ListColumnType, ListDataColumn } from 'components/tables/ItemsListTable/types';
import { Item } from 'types/item.enum';
import { toJS } from 'mobx';
import { useStore } from '../../store';

const columnsFiltersTypeMap = {
    [ListColumnType.DATE_TIME]: FilterInputType.DATE,
    [ListColumnType.TEXT]: FilterInputType.TEXT,
    [ListColumnType.TEXT_WITH_TOOLTIP]: FilterInputType.TEXT,
    [ListColumnType.ACTIONS]: FilterInputType.TEXT,
    [ListColumnType.TEXT_WITH_TOOLTIP_EDITABLE]: FilterInputType.TEXT,
    [ListColumnType.TEXT_EDITABLE]: FilterInputType.TEXT,
    [ListColumnType.DATE_TIME_EDITABLE]: FilterInputType.DATE,
    [ListColumnType.TEXT_ENUM_FILTERED]: FilterInputType.SELECT,
    [ListColumnType.SELECT]: FilterInputType.SELECT,
};

interface UseFilterParams {
    onFilterClose: (filterName: string) => void;
    onCloseAllFilters: () => void;
    listColumns: ListColumn[];
    itemName: Item;
    extraFilters?: Filter[];
}

export function useFilters({
    listColumns,
    onFilterClose,
    onCloseAllFilters,
    itemName,
    extraFilters = [],
}: UseFilterParams) {
    const listStore = useStore('list');
    const initialFilters: Filter[] = useMemo(
        () =>
            listColumns
                .filter((el): el is ListDataColumn => el.type !== ListColumnType.ACTIONS)
                .reduce(
                    (filtersAcc, column) =>
                        column.showFilter
                            ? [
                                  ...filtersAcc,
                                  {
                                      name: column.fieldName,
                                      title: column.title,
                                      type: column.filterType || columnsFiltersTypeMap[column.type],
                                      isEnabled: false,
                                      ...(column.type === ListColumnType.TEXT_ENUM_FILTERED && {
                                          values: column.filterValues,
                                      }),
                                      ...(column.type === ListColumnType.SELECT && {
                                          values: column.selectOptions?.map((option) => option.selectValue),
                                      }),
                                  },
                              ]
                            : filtersAcc,
                    extraFilters as Filter[],
                ),
        [listColumns, extraFilters],
    );

    const [filters, setFilters] = useState<Filter[]>(
        listStore.lists[itemName]?.filtersComponentParams || initialFilters,
    );

    useEffect(() => {
        listStore.setListParams({ filtersComponentParams: filters }, itemName);
    }, [filters, itemName, listStore]);

    const toggleFilter = (filterName: string) => {
        const filterIndex = filters.findIndex((filter) => filter.name === filterName);

        if (filters[filterIndex].isEnabled) {
            onFilterClose(filterName);
        }

        setFilters((prevFilters) =>
            prevFilters.map((filter) => {
                if (filter.name === filterName) {
                    // eslint-disable-next-line no-param-reassign
                    filter.isEnabled = !filter.isEnabled;
                }

                return filter;
            }),
        );
    };

    const closeAllFilters = () => {
        setFilters((prevFilters) =>
            prevFilters.map((filter) => {
                if (!filter.disableClose) {
                    // eslint-disable-next-line no-param-reassign
                    filter.isEnabled = false;
                }

                return filter;
            }),
        );
        onCloseAllFilters();
    };

    return { filters, toggleFilter, closeAllFilters, filterValues: toJS(listStore.lists[itemName]?.queryFilters) };
}
