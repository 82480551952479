import React from 'react';
import { InputType } from 'reactstrap/es/Input';
import { Control, useController, UseControllerProps } from 'react-hook-form';

import { StyledDisabledInput, StyledInput } from 'components/fields/styles';
import { getTitleWithAsterisk } from 'lib/text';
import { getErrorByName } from 'lib/form';
import { FormError } from 'components/forms/FormError';
import { FormInfo } from '../forms/FormInfo';
import { useHiddenDisabled } from './useHiddenDisabled';

interface FormTextInputProps {
    title: string;
    name: UseControllerProps['name'];
    rules?: UseControllerProps['rules'];
    placeholder?: string;
    control: Control<any>;
    onChange?: (string) => void;
    defaultValue?: string;
    inputType?: InputType;
    info?: string;
    sendNull?: boolean;
    isDisabledProp?: boolean;
    isHiddenProp?: boolean;
}

export const FormTextInput: React.FC<FormTextInputProps> = ({
    title,
    rules,
    control,
    name,
    defaultValue,
    placeholder,
    onChange,
    inputType = 'text',
    info,
    sendNull,
    isDisabledProp,
    isHiddenProp,
}) => {
    const {
        field,
        formState: { errors },
    } = useController({ rules, control, name, defaultValue: defaultValue || '' });
    const { isHidden, isDisabled } = useHiddenDisabled({ isDisabledProp, isHiddenProp });

    const error = getErrorByName(name, errors);

    const handleChange = (e) => {
        const { value } = e.target;

        if (sendNull && !value) {
            field.onChange(null);
        } else {
            field.onChange(value);
        }

        if (onChange) {
            onChange(value);
        }
    };

    if (isHidden) {
        return null;
    }

    return (
        <div className="align-items-center">
            <label className="form-control-label">{getTitleWithAsterisk(title, !!rules?.required)}</label>
            {isDisabled && (
                <StyledDisabledInput disabled={true} {...field} type={inputType} placeholder={placeholder} />
            )}
            {!isDisabled && (
                <StyledInput
                    {...field}
                    onChange={handleChange}
                    type={inputType}
                    placeholder={placeholder}
                    disabled={isDisabled}
                />
            )}
            <FormError error={error} />
            <FormInfo text={info} />
        </div>
    );
};
