const isDev = require('./isDev');

const config = {
    api: {
        host: process.env.REACT_APP_API_HOST || '127.0.0.1',
        port: process.env.REACT_APP_API_PORT || 3001,
        url: process.env.REACT_APP_API_URL || 'api',

        getProdHostPort() {
            return `${process.env.REACT_APP_API_HOST || window.location.host}`;
        },
        getDevHostPort() {
            return `${this.host}:${this.port}`;
        },
        getBaseUrl(url) {
            return `${this.getHost()}/${url || this.url}`;
        },
        getHost() {
            return `${window.location.protocol}//${isDev() ? this.getDevHostPort() : this.getProdHostPort()}`;
        },
    },
    formats: {
        dateTime: 'dd-MM-yyyy HH:mm',
        date: 'dd-MM-yyyy',
        time: 'HH:mm',
        isoDate: 'yyyy-MM-dd',
    },
};

module.exports = config;
