import styled from 'styled-components';

export const TableToolkitContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1.5em;
    margin-left: 1.5em;
    margin-right: 1.5em;
`;

export const TableButtonsToolkitContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-self: center;
`;
