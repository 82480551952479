import { Item } from 'types/item.enum';
import { useHistory } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { generateRequestsByItemName } from 'api';
import { ItemFieldEditForm, ItemInputDictionary, ItemInputField, SendFieldsFromDictionary } from '../input.types';
import { useStore } from '../../../store';
import { getDictionaryDefaultValueByFieldType } from '../../../lib/dictionary';

interface UseEditFormParams {
    itemName: Item;
    backPath?: string;
    id?: number | string;
    fields: ItemFieldEditForm;
}

export function useItemAndDefaultFields<DATA extends Record<string, any> = Record<string, any>>(
    params: UseEditFormParams,
) {
    const dictionaryStore = useStore('dictionary');

    const { location } = useHistory();
    const arrayPath = location.pathname.split('/');
    const { id = arrayPath.slice(-2)[0], itemName, fields } = params;
    const { getItem } = generateRequestsByItemName<DATA>(itemName);
    const [isLoaded, setIsLoaded] = useState(false);
    const [item, setItem] = useState<DATA>();
    const [defaultValues, setDefaultValues] = useState<Record<string, any>>({});
    const [formFields] = useState(
        fields.flatMap((el) => el).filter((el): el is ItemInputField => !('isEditFormViewField' in el)),
    );
    formFields
        .filter((el): el is ItemInputDictionary => 'dictionary' in el)
        .forEach((el) => {
            dictionaryStore.loadDictionary(el.dictionary);
        });

    useEffect(() => {
        if (!item) {
            return;
        }
        const formFieldsMap = formFields.reduce(
            (acc, el) => ({
                ...acc,
                [el.fieldName]: {
                    fieldName: el.itemDefaultFieldName || el.fieldName,
                    ...('dictionary' in el && {
                        sendFieldsFromDictionary: el.sendFieldsFromDictionary || SendFieldsFromDictionary.ID,
                        dictionary: el.dictionary,
                    }),
                },
            }),
            {},
        );
        const defValues = formFields.reduce((acc, inputField) => {
            const field = formFieldsMap[inputField.fieldName];
            const itemValue = item && item[field.fieldName];

            if (field.dictionary) {
                const sendFieldsFromDictionary = field.sendFieldsFromDictionary || SendFieldsFromDictionary.ID;
                const [dictionaryType, dictionaryId] = Object.entries(field.dictionary)[0];
                if (dictionaryStore.items[dictionaryType][dictionaryId]) {
                    const dictionary = dictionaryStore.items[dictionaryType][dictionaryId];

                    const dictionaryFieldValue = getDictionaryDefaultValueByFieldType(
                        itemValue,
                        sendFieldsFromDictionary,
                        dictionary,
                    );

                    return {
                        ...acc,
                        [inputField.fieldName]: item ? dictionaryFieldValue : '',
                    };
                }
            }

            return { ...acc, [inputField.fieldName]: item ? itemValue : '' };
        }, {});

        setDefaultValues(defValues);
        setIsLoaded(true);
    }, [item, fields, formFields, dictionaryStore.items]);

    const getItemUpdateState = useCallback(async () => {
        const itemData = await getItem({ id }, location.search);
        setItem(itemData);
    }, [getItem, id, location.search]);

    useEffect(() => {
        if (id) {
            getItemUpdateState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return { isLoaded, defaultValues, item };
}
