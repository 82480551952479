import { Item } from 'types/item.enum';
import { useHistory } from 'react-router-dom';
import { generateRequestsByItemName } from 'api';
import { useForm } from 'react-hook-form';
import { queryStringToJson } from '../../../lib/parseUrl';

interface UseEditFormParams {
    itemName: Item;
    defaultValues: Record<string, any>;
    backPath?: string;
    id?: number | string;
    sendSearchData?: boolean;
}

export function useEditForm<DATA extends Record<string, any> = Record<string, any>>(params: UseEditFormParams) {
    const { location, push } = useHistory();
    const arrayPath = location.pathname.split('/');
    const { id = arrayPath.slice(-2)[0], itemName, backPath, defaultValues, sendSearchData } = params;
    const { updateItem } = generateRequestsByItemName<DATA>(itemName);
    const { handleSubmit, control, formState, getValues, watch } = useForm({ defaultValues });
    watch();
    const { dirtyFields } = formState;

    const handleFormSubmit = handleSubmit(async (data) => {
        const searchData = sendSearchData && location.search ? queryStringToJson(location.search) : {};
        const updateEntries = Object.entries(data).filter(([k]) => dirtyFields[k]);
        if (updateEntries.length) {
            const updates = Object.fromEntries(updateEntries);

            await updateItem({ id }, { ...updates, ...searchData });
        }

        if (backPath) {
            push(backPath);
        }
    });

    return { id, handleFormSubmit, control, getValues };
}
