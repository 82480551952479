export function preparePlainText(text: string | any[] | object | undefined) {
    if (text === undefined) {
        return '';
    }

    if (text instanceof Array) {
        return text.join(', ');
    }

    if (typeof text === 'object') {
        return JSON.stringify(text);
    }

    return String(text);
}

export function getTitleWithAsterisk(title: string, required = false) {
    return `${title.toUpperCase()}${required ? ' *' : ''}`;
}

export function convertStringOrNullOrNumber(text: string) {
    const number = +text;
    if (!Number.isNaN(number)) {
        return number;
    }

    if (text === 'null') {
        return null;
    }

    return text;
}
