import React, { useCallback, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { Control, useController, UseControllerProps } from 'react-hook-form';
import { getTitleWithAsterisk } from 'lib/text';
import { getErrorByName } from 'lib/form';
import { FormError } from 'components/forms/FormError';
import FormSelect from '../react-select/FormSelect';

interface FormMultiSelectInputProps {
    label: string;
    name: UseControllerProps['name'];
    rules?: UseControllerProps['rules'];
    control: Control<any>;
    defaultValue?: string | null;
    selectOptions?: Array<{ selectValue: any; title: any }>;
}

export const FormMultiSelectInput: React.FC<FormMultiSelectInputProps> = ({
    label,
    rules,
    control,
    name,
    defaultValue,
    selectOptions,
}) => {
    const {
        field,
        formState: { errors },
    } = useController({
        rules,
        control,
        name,
        defaultValue: defaultValue || defaultValue === null ? defaultValue : '',
    });

    const error = getErrorByName(name, errors);

    const [selectorValue, setSelectorValue] = useState();

    useEffect(() => {
        setSelectorValue((prevValue) => {
            const newValue = (field.value || [])
                .map((value) => selectOptions?.find((option) => option.selectValue === value))
                .map((option) => ({ value: option.selectValue, label: option.title }))
                .filter((el) => !!el);
            if (!isEqual(prevValue, newValue)) {
                return newValue;
            }

            return prevValue;
        });
    }, [selectOptions, field.value]);

    const handleChange = (selected) => {
        field.onChange(selected.map((x) => x.value));
    };

    return (
        <div className="align-items-center">
            <label className="form-control-label">{getTitleWithAsterisk(label, !!rules?.required)}</label>
            <FormSelect
                onChange={handleChange}
                options={(selectOptions || []).map(({ selectValue, title }) => ({ value: selectValue, label: title }))}
                forwardedRef={field.ref}
                onBlur={field.onBlur}
                isMulti={true}
                value={selectorValue}
            />
            <FormError error={error} />
        </div>
    );
};
