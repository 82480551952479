import NotificationAlert, { AlertOptions } from 'react-notification-alert';
import { makeAutoObservable, observable } from 'mobx';

class NotificationStore {
    constructor() {
        makeAutoObservable(this);
    }

    @observable ref: NotificationAlert | undefined;

    @observable setNotificationRef(ref: NotificationAlert) {
        this.ref = ref;
    }

    @observable showNotify({ place, message, type, autoDismiss = 7 }: Partial<AlertOptions>) {
        const options = {
            place,
            message,
            type,
            icon: 'ni ni-bell-55',
            autoDismiss,
        };

        this.ref?.notificationAlert(options as AlertOptions);
    }

    @observable showSuccessNotify(message) {
        const options = {
            place: 'br',
            message,
            type: 'success',
            icon: 'ni ni-bell-55',
            autoDismiss: 7,
        };

        this.ref?.notificationAlert(options as AlertOptions);
    }
}

export default new NotificationStore();
