import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import Loader from './components/Loader';

export const Router: React.FC = () => {
    return (
        <Switch>
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
            <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
            <Route path="/loader" render={() => <Loader />} />
            <Redirect from="/" to="/admin" />
        </Switch>
    );
};
