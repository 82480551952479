import React from 'react';
import { FilterElementContainer, FilterElement, FilterTitle } from 'elements/filters';

import CloseButton from 'components/buttons/CloseButton';
import { FromGroupStyled } from 'components/filters/components/styles';
import { StyledInput } from 'components/fields/styles';

interface TextInputProps {
    onChange: (value: string) => void;
    handleClose: () => void;
    isHideCloseButton?: boolean;
    placeholder?: string;
    label?: string;
    defaultValue?: string;
}

const NumberInput: React.FC<TextInputProps> = ({
    isHideCloseButton,
    handleClose,
    placeholder,
    label,
    onChange,
    defaultValue,
}) => {
    return (
        <FilterElement>
            <FilterElementContainer>
                <FromGroupStyled>
                    {label && <FilterTitle className="form-control-label">{label}</FilterTitle>}
                    <StyledInput
                        type="number"
                        bsSize="sm"
                        onChange={(e) => onChange(e.target.value)}
                        placeholder={placeholder}
                        defaultValue={defaultValue}
                    />
                </FromGroupStyled>
                {!isHideCloseButton && <CloseButton handleClose={handleClose} />}
            </FilterElementContainer>
        </FilterElement>
    );
};

export default NumberInput;
