import React from 'react';
import { ItemInputField, ItemInputFieldType } from 'components/forms/input.types';
import { Col, Row } from 'reactstrap';
import { calculateCellSize, calculateRowSizeValues } from 'lib/grid';
import { FormTextInput } from 'components/form-inputs/FormTextInput';
import { FormNumberInput } from 'components/form-inputs/FormNumberInput';
import { FormDateTimeInput } from 'components/form-inputs/FormDateTimeInput';
import { Control } from 'react-hook-form';
import { FromBooleanInput } from 'components/form-inputs/FormBooleanInput';
import { FormSelectInput } from 'components/form-inputs/FormSelectInput';
import { FormMultiSelectInput } from 'components/form-inputs/FormMultiSelectInput';
import { generateIntegerSelectOptions } from 'lib/select';
import FormDictionarySelect from '../../form-inputs/FormDictionarySelect';
import { FormDateInput } from '../../form-inputs/FormDateInput';

interface ViewItemFormRowProps {
    cells: ItemInputField[];
    control: Control<any>;
}

export const InputItemFormRow: React.FC<ViewItemFormRowProps> = ({ cells, control }) => {
    const { totalSizedCellsWidth, notSizedCellsCount } = calculateRowSizeValues(cells);

    return (
        <Row>
            {cells.map((field) => {
                const cellSize = calculateCellSize(totalSizedCellsWidth, notSizedCellsCount, field.size);

                if (field.type === ItemInputFieldType.TEXT) {
                    return (
                        <Col key={field.fieldName} md={cellSize} className="mb-2">
                            <FormTextInput
                                control={control}
                                name={field.fieldName}
                                title={field.title}
                                rules={field.rules}
                                defaultValue={field.defaultValue}
                            />
                        </Col>
                    );
                }

                if (field.type === ItemInputFieldType.NUMBER) {
                    return (
                        <Col key={field.fieldName} md={cellSize} className="mb-2">
                            <FormNumberInput
                                control={control}
                                name={field.fieldName}
                                title={field.title}
                                rules={field.rules}
                                defaultValue={field.defaultValue}
                                isDisabledProp={field.isDisabled}
                            />
                        </Col>
                    );
                }

                if (field.type === ItemInputFieldType.BOOLEAN) {
                    return (
                        <Col key={field.fieldName} md={cellSize} className="mb-2">
                            <FromBooleanInput
                                control={control}
                                name={field.fieldName}
                                title={field.title}
                                rules={field.rules}
                                defaultValue={field.defaultValue}
                                disableOn={field.disableOn}
                                disableOff={field.disableOff}
                            />
                        </Col>
                    );
                }

                if (field.type === ItemInputFieldType.SELECT) {
                    return (
                        <Col key={field.fieldName} md={cellSize} className="mb-2">
                            <FormSelectInput
                                control={control}
                                name={field.fieldName}
                                label={field.title}
                                rules={field.rules}
                                defaultValue={field.defaultValue}
                                selectOptions={
                                    field.integerSelectOptions
                                        ? generateIntegerSelectOptions(field.integerSelectOptions)
                                        : field.selectOptions
                                }
                            />
                        </Col>
                    );
                }

                if (field.type === ItemInputFieldType.SELECT_MULTI) {
                    return (
                        <Col key={field.fieldName} md={cellSize} className="mb-2">
                            <FormMultiSelectInput
                                control={control}
                                name={field.fieldName}
                                label={field.title}
                                rules={field.rules}
                                defaultValue={field.defaultValue}
                                selectOptions={
                                    field.integerSelectOptions
                                        ? generateIntegerSelectOptions(field.integerSelectOptions)
                                        : field.selectOptions
                                }
                            />
                        </Col>
                    );
                }

                if (field.type === ItemInputFieldType.DICTIONARY) {
                    return (
                        <Col key={field.fieldName} md={cellSize} className="mb-2">
                            <FormDictionarySelect
                                control={control}
                                name={field.fieldName}
                                label={field.title}
                                rules={field.rules}
                                defaultValue={field.defaultValue}
                                dictionary={field.dictionary}
                            />
                        </Col>
                    );
                }

                if (field.type === ItemInputFieldType.DICTIONARY_MULTI) {
                    return (
                        <Col key={field.fieldName} md={cellSize} className="mb-2">
                            <FormDictionarySelect
                                control={control}
                                name={field.fieldName}
                                label={field.title}
                                rules={field.rules}
                                defaultValue={field.defaultValue}
                                dictionary={field.dictionary}
                                isMulti={true}
                            />
                        </Col>
                    );
                }

                if (field.type === ItemInputFieldType.DATE_TIME) {
                    return (
                        <Col key={field.fieldName} md={cellSize} className="mb-2">
                            <FormDateTimeInput
                                id={field.fieldName}
                                title={field.title}
                                control={control}
                                name={field.fieldName}
                                rules={field.rules}
                                defaultValue={field.defaultValue}
                                showEmpty={field.showEmpty}
                                datesBefore={field.datesBefore}
                                datesFrom={field.datesFrom}
                            />
                        </Col>
                    );
                }

                if (field.type === ItemInputFieldType.DATE) {
                    return (
                        <Col key={field.fieldName} md={cellSize} className="mb-2">
                            <FormDateInput
                                id={field.fieldName}
                                title={field.title}
                                control={control}
                                name={field.fieldName}
                                rules={field.rules}
                                defaultValue={field.defaultValue}
                                showEmpty={field.showEmpty}
                                datesBefore={field.datesBefore}
                                datesFrom={field.datesFrom}
                            />
                        </Col>
                    );
                }

                return null;
            })}
        </Row>
    );
};
