import React from 'react';
import { ItemFieldCreateForm, ItemInputFieldType } from 'components/forms/input.types';
import { Item } from 'types/item.enum';
import { CreateItemForm } from 'components/forms/CreateItemForm';
import { AdminDictionaryId, DictionaryType } from '../../../types/dictionary';

const fields: ItemFieldCreateForm = [
    [
        {
            title: 'Название компании',
            fieldName: 'title',
            type: ItemInputFieldType.TEXT,
            rules: {
                required: true,
            },
        },
        {
            title: 'Показывать?',
            fieldName: 'isEnabled',
            type: ItemInputFieldType.BOOLEAN,
        },
    ],
    [
        {
            title: 'Банки',
            fieldName: 'bankIds',
            type: ItemInputFieldType.DICTIONARY_MULTI,
            dictionary: {
                [DictionaryType.ADMIN]: AdminDictionaryId.BANK,
            },
        },
    ],
];

const View: React.FC = () => {
    return <CreateItemForm title="Добавить страховую компанию" fields={fields} itemName={Item.INSURANCE_COMPANY} />;
};

export default View;
