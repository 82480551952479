import React, { FocusEventHandler } from 'react';
import ReactSelect from 'react-select';
import { SelectValue } from './types';

interface FormSelectorProps {
    onChange: (value: any) => void;
    options: SelectValue[];
    forwardedRef?: React.Ref<any>;
    onBlur?: FocusEventHandler<HTMLInputElement>;
    isMulti?: boolean;
    defaultValue?: SelectValue[] | SelectValue;
    value?: SelectValue[] | SelectValue;
    disabled?: boolean;
    isClearable?: boolean;
}

const FormSelect: React.FC<FormSelectorProps> = ({
    onChange,
    options,
    forwardedRef,
    onBlur,
    isMulti,
    defaultValue,
    value,
    disabled,
    isClearable,
}) => {
    return (
        <ReactSelect
            isClearable={isClearable}
            ref={forwardedRef}
            onBlur={onBlur}
            placeholder="Выбрать..."
            defaultValue={defaultValue}
            value={value}
            // @ts-ignore
            noOptionsMessage={() => i18n.intl.translateMessage('no_elements_for_selection')}
            // @ts-ignore
            onChange={onChange}
            // @ts-ignore
            options={options}
            isMulti={isMulti}
            isDisabled={disabled}
            theme={(theme) => ({
                ...theme,
                border: 'none',
                colors: {
                    ...theme.colors,
                    primary25: '#ced4da',
                    primary: '#8898aa',
                },
            })}
            styles={{
                input: (styles) => ({ ...styles, margin: 'none' }),
                control: (styles) => ({
                    ...styles,
                    minHeight: '3.285em',
                    border: '1px solid #dee2e6',
                    boxShadow: 'none',
                    ':hover': {
                        border: '1px solid #dee2e6',
                    },
                }),
                placeholder: (styles) => ({
                    ...styles,
                    fontWeight: 400,
                    color: '#8898aa',
                    fontSize: '0.9em',
                }),
                singleValue: (styles) => ({
                    ...styles,
                    fontWeight: 400,
                    color: '#8898aa',
                    fontSize: '0.9em',
                }),
                valueContainer: (styles) => ({ ...styles, minHeight: '2em', maxHeight: '2em' }),
                indicatorsContainer: (styles) => ({
                    ...styles,
                    alignSelf: 'center',
                }),
            }}
        />
    );
};

export default FormSelect;
