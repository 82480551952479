import styled from 'styled-components';
import { Button } from 'reactstrap';

export const NoShadowButton = styled(Button)`
    box-shadow: none;

    :hover {
        box-shadow: none;
        transform: none;
    }
`;
