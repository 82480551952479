import React, { useState, useRef } from 'react';
import { formats } from 'lib/config';
import DatePicker, { ReactDatePicker } from 'react-datepicker';
import { StyledCellInput } from './styles';

interface CellEditDataInputProps {
    id: string;
    value: any;
    onUpdate: (data: any) => void;
    onBlur: () => void;
}

export const CellEditDateTimeInput: React.FC<CellEditDataInputProps> = ({ id, value, onUpdate, onBlur }) => {
    const [innerValue, setValue] = useState(value);

    const datePickerRef = useRef<ReactDatePicker>(null);

    const datePickerId = `edit-date-id-${id}`;

    const setFocusById = () => document?.getElementById(datePickerId)?.focus();

    return (
        <DatePicker
            ref={datePickerRef}
            onBlur={onBlur}
            selectsRange={false}
            locale="ru"
            selected={new Date(innerValue)}
            showTimeSelect={true}
            timeInputLabel="Время"
            timeCaption="Время"
            timeIntervals={15}
            dateFormatCalendar={formats.date}
            dateFormat={formats.dateTime}
            timeFormat={formats.time}
            id={datePickerId}
            onChange={(newDate, event) => {
                setFocusById();
                setValue(newDate);
                if (!event) {
                    onUpdate(newDate?.toISOString());
                    onBlur();
                }
            }}
            isClearable={false}
            autoFocus={true}
            customInput={<StyledCellInput disabled={true} bsSize="sm" />}
        />
    );
};
