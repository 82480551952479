import React from 'react';
import { StyledFormInfo } from 'elements/forms';

interface Props {
    text?: string;
}

export const FormInfo: React.FC<Props> = ({ text }) => {
    if (!text) {
        return null;
    }

    return <StyledFormInfo>{text}</StyledFormInfo>;
};
