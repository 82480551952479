import React from 'react';
import { Route } from 'react-router-dom';
import { orderBy } from 'lodash';

import { ISimpleRoute, ICollapsedRoute, TRoute, IHiddenRoute } from 'routes/types';

export const isCollapsedRoute = (route: unknown): route is ICollapsedRoute => {
    return typeof (route as ICollapsedRoute).collapse !== 'undefined';
};

export const isSimpleRoute = (route: unknown): route is ISimpleRoute => {
    return typeof (route as ICollapsedRoute).collapse === 'undefined';
};

export const isHiddenRoute = (route: unknown): route is IHiddenRoute => {
    return (route as IHiddenRoute).isHiddenInMenu;
};

export const getRoutes = <T extends TRoute>(routes: T[], layout: string): JSX.Element[] => {
    const orderedRoutes = orderBy(routes, 'isHiddenInMenu', 'asc');

    return orderedRoutes.reduce((acc: JSX.Element[], route: TRoute): JSX.Element[] => {
        if (isCollapsedRoute(route) && route.collapse) {
            return [...acc, ...getRoutes(route.views, layout)];
        }

        if ((isHiddenRoute(route) || isSimpleRoute(route)) && route.layout === layout) {
            return [
                ...acc,
                <Route path={route.layout + route.path} component={route.component} key={route.layout + route.path} />,
            ];
        }

        return acc;
    }, []);
};

export default getRoutes;
