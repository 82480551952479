import React, { useRef, useState } from 'react';
import { endOfDay } from 'date-fns';
import { formats } from 'lib/config';
import { StyledInput } from 'components/fields/styles';
import DatePicker, { ReactDatePicker } from 'react-datepicker';
import { getTitleWithAsterisk } from 'lib/text';
import { Control, useController, UseControllerProps } from 'react-hook-form';
import { getErrorByName } from 'lib/form';
import { FormError } from 'components/forms/FormError';
import { useHiddenDisabled } from './useHiddenDisabled';

interface FormDateTimeInputProps {
    id: number | string;
    title: string;
    isClearable?: boolean;
    name: UseControllerProps['name'];
    rules?: UseControllerProps['rules'];
    control: Control<any>;
    defaultValue?: Date;
    isDisabledProp?: boolean;
    isHiddenProp?: boolean;
    showEmpty?: boolean;
    datesBefore?: Date | string;
    datesFrom?: Date | string;
}

export const FormDateInput: React.FC<FormDateTimeInputProps> = ({
    id,
    title,
    isClearable = false,
    rules,
    control,
    name,
    defaultValue,
    isDisabledProp,
    isHiddenProp,
    showEmpty,
    datesFrom,
    datesBefore,
}) => {
    const [innerValue, setValue] = useState<Date | null>(defaultValue || null);
    const { isHidden, isDisabled } = useHiddenDisabled({ isDisabledProp, isHiddenProp });

    const datePickerRef = useRef<ReactDatePicker>(null);
    const datePickerId = `form-date-time-input-id-${id}`;
    const setFocusById = () => document?.getElementById(datePickerId)?.focus();

    const {
        field: { onChange },
        formState: { errors },
    } = useController({ rules, control, name, defaultValue: innerValue });

    const error = getErrorByName(name, errors);

    if (isHidden) {
        return null;
    }

    const getSelected = () => {
        if (innerValue) {
            return new Date(innerValue);
        }
        if (showEmpty) {
            return null;
        }

        return new Date();
    };

    return (
        <div className="align-items-center">
            <label className="form-control-label">{getTitleWithAsterisk(title, !!rules?.required)}</label>
            <DatePicker
                ref={datePickerRef}
                selectsRange={false}
                locale="ru"
                selected={getSelected()}
                timeInputLabel="Время"
                timeCaption="Время"
                timeIntervals={15}
                dateFormatCalendar={formats.date}
                dateFormat={formats.date}
                timeFormat={formats.time}
                minDate={datesFrom ? new Date(datesFrom) : undefined}
                maxDate={datesBefore ? new Date(datesBefore) : undefined}
                id={datePickerId}
                disabled={isDisabled}
                onChange={(newDate) => {
                    setFocusById();
                    setValue(newDate);
                    onChange(newDate ? endOfDay(newDate).toISOString() : null);
                }}
                isClearable={isClearable}
                customInput={<StyledInput disabled={true} />}
            />
            <FormError error={error} />
        </div>
    );
};
