import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';

import { ListColumn } from 'components/tables/ItemsListTable/types';
import { useColumns } from 'components/tables/useColumns';

interface ViewItemTableProps {
    listColumns: ListColumn[];
    title: string;
    data: Array<Record<string, any>> | null;
    fieldName: string;
}

const ViewItemTable: React.FC<ViewItemTableProps> = ({ listColumns, title, data, fieldName }) => {
    const columns = useColumns({ columns: listColumns, parentField: fieldName });

    return (
        <React.Fragment>
            <label className="form-control-label">{title.toUpperCase()}</label>
            <BootstrapTable
                data={data?.map((el, i) => ({ ...el, keyFieldId: Object.keys(el).join('.') + i })) || []}
                columns={columns}
                keyField="keyFieldId"
                bootstrap4={true}
                bordered={true}
                id="react-bs-table"
                remote={false}
                rowClasses="bs-table-row"
                classes="rounded-bs-table mb-0"
            />
        </React.Fragment>
    );
};

export default ViewItemTable;
