import React, { useCallback, useState } from 'react';

import TableRowActionIcon from 'components/icons/ActionIcon';
import ConfirmationModal from 'components/modals/ConfirmationModal';

interface TableDeleteButtonProps {
    onRemove: () => void;
    id: any;
}

const TableRemoveButtonWithModal: React.FC<TableDeleteButtonProps> = ({ onRemove, id }) => {
    const [isModalOpen, setModalOpen] = useState(false);

    const handleDelete = useCallback(
        (e) => {
            e.stopPropagation();
            setModalOpen(true);
        },
        [isModalOpen],
    );

    return (
        <React.Fragment>
            <ConfirmationModal
                title="Удаление записи"
                text={`Подтвердите удаление записи #${id}`}
                isOpen={isModalOpen}
                toggleOpen={setModalOpen}
                callback={onRemove}
            />
            <TableRowActionIcon onClick={handleDelete} activeColor="#f5365c" title="Удалить" className="fa fa-trash" />
        </React.Fragment>
    );
};

export default TableRemoveButtonWithModal;
