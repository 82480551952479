import { UseControllerProps } from 'react-hook-form';
import { DictionaryParams } from 'types/dictionary';
import { ItemField } from './ViewItemForm/types';

export interface ItemInputFieldParams {
    // Название поля, которое будет передано в запрос
    fieldName: string;
    // Название поля, которое будет взято из объекта в качестве дефолтного значения для формы (если отличается от fieldName)
    itemDefaultFieldName?: string;
    title: string;
    size?: number;
    defaultValue?: any;
    rules?: UseControllerProps['rules'];
    isDisabled?: boolean;
    disableByCondition?: (formValues: Record<any, any>, row?: Record<any, any>) => boolean;
    hideByCondition?: (formValues: Record<any, any>, row?: Record<any, any>) => boolean;
}

interface ItemInputTextField extends ItemInputFieldParams {
    type: ItemInputFieldType.TEXT | ItemInputFieldType.TEXT_AREA | ItemInputFieldType.NUMBER;
    sendNullIfEmpty?: boolean;
}

interface ItemInputBooleanField extends ItemInputFieldParams {
    type: ItemInputFieldType.BOOLEAN;
    disableOn?: boolean;
    disableOff?: boolean;
}

interface ItemInputDateField extends ItemInputFieldParams {
    type: ItemInputFieldType.DATE_TIME | ItemInputFieldType.DATE;
    format?: string;
    showEmpty?: boolean;
    datesBefore?: Date | string;
    datesFrom?: Date | string;
}

interface ItemInputSelectField extends ItemInputFieldParams {
    type: ItemInputFieldType.SELECT | ItemInputFieldType.SELECT_MULTI;
    selectOptions?: Array<{ selectValue: any; title: any }>;
    integerSelectOptions?: { minValue?: number; maxValue: number; nullable?: boolean };
}

export enum SendFieldsFromDictionary {
    ID = 'id',
    TITLE = 'title',
}

export interface ItemInputDictionary extends ItemInputFieldParams {
    type: ItemInputFieldType.DICTIONARY | ItemInputFieldType.DICTIONARY_MULTI;
    dictionary: DictionaryParams;
    // Поле, которое будет отправлено из словаря в запрос
    sendFieldsFromDictionary?: SendFieldsFromDictionary;
}

export type ItemInputField =
    | ItemInputTextField
    | ItemInputDateField
    | ItemInputSelectField
    | ItemInputDictionary
    | ItemInputBooleanField;

export type ItemFieldCreateForm = ItemInputField[][];
export type ItemFieldEditForm = Array<Array<ItemInputField | ItemField>>;

export enum ItemInputFieldType {
    TEXT = 'TEXT',
    NUMBER = 'NUMBER',
    BOOLEAN = 'BOOLEAN',
    TEXT_AREA = 'TEXT_AREA',
    DATE_TIME = 'DATE_TIME',
    DATE = 'DATE',
    SELECT = 'SELECT',
    SELECT_MULTI = 'SELECT_MULTI',
    DICTIONARY = 'DICTIONARY',
    DICTIONARY_MULTI = 'DICTIONARY_MULTI',
}
