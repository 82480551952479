import { TRouteArray } from 'routes/types';
import {
    CREATE_BY_STRING_MASS_ROUTE_POSTFIX,
    CREATE_FORM_ROUTE_POSTFIX,
    DELETE_BY_STRING_MASS_ROUTE_POSTFIX,
    EDIT_FORM_ROUTE_POSTFIX,
} from './constants';
import Login from '../views/auth/Login';
import BankList from '../views/crud/banks/BankList';
import BankCreate from '../views/crud/banks/BankCreate';
import BankEdit from '../views/crud/banks/BankEdit';
import { Item } from '../types/item.enum';
import InsuranceCompanyList from '../views/crud/insuranceCompany/InsuranceCompanyList';
import InsuranceCompanyCreate from '../views/crud/insuranceCompany/InsuranceCompanyCreate';
import InsuranceCompanyEdit from '../views/crud/insuranceCompany/InsuranceCompanyEdit';

export * from 'routes/types';
export * from 'routes/getRoutes';

const routes: TRouteArray = [
    {
        isHiddenInMenu: true,
        path: '/login',
        component: Login,
        layout: '/auth',
    },
    {
        path: `/${Item.BANK}`,
        name: 'Банки',
        icon: 'ni ni-align-left-2 text-primary',
        component: BankList,
        layout: '/admin',
    },
    {
        path: `/${Item.BANK}/${CREATE_FORM_ROUTE_POSTFIX}`,
        name: 'Создание банка',
        icon: 'ni ni-align-left-2 text-primary',
        component: BankCreate,
        layout: '/admin',
        isHiddenInMenu: true,
    },
    {
        path: `/${Item.BANK}/:id/${EDIT_FORM_ROUTE_POSTFIX}`,
        name: 'Редактирование банка',
        icon: 'ni ni-align-left-2 text-primary',
        component: BankEdit,
        layout: '/admin',
        isHiddenInMenu: true,
    },
    {
        path: `/${Item.INSURANCE_COMPANY}`,
        name: 'Страховые компании',
        icon: 'ni ni-align-left-2 text-primary',
        component: InsuranceCompanyList,
        layout: '/admin',
    },
    {
        path: `/${Item.INSURANCE_COMPANY}/${CREATE_FORM_ROUTE_POSTFIX}`,
        name: 'Создание стразовой компании',
        icon: 'ni ni-align-left-2 text-primary',
        component: InsuranceCompanyCreate,
        layout: '/admin',
        isHiddenInMenu: true,
    },
    {
        path: `/${Item.INSURANCE_COMPANY}/:id/${EDIT_FORM_ROUTE_POSTFIX}`,
        name: 'Редактирование стразовой компании',
        icon: 'ni ni-align-left-2 text-primary',
        component: InsuranceCompanyEdit,
        layout: '/admin',
        isHiddenInMenu: true,
    },
];

export default routes;
