import React from 'react';

import CloseButton from 'components/buttons/CloseButton';
import { FromGroupStyled } from 'components/filters/components/styles';
import FilterSelect from 'components/react-select/FilterSelect';
import { FilterElementContainer, FilterElement, FilterTitle } from 'elements/filters';
import { useDictionary } from 'hooks/useDictionary';
import { observer } from 'mobx-react-lite';
import { DictionaryParams } from 'types/dictionary';

interface FilterSelectorProps {
    dictionary: DictionaryParams;
    onChange: (value: any) => void;
    handleClose: () => void;
    isHideCloseButton?: boolean;
    placeholder?: string;
    label?: string;
    defaultValue?: number;
}

const DictionarySelector: React.FC<FilterSelectorProps> = observer(
    ({ isHideCloseButton, dictionary, onChange, handleClose, label, defaultValue }) => {
        const values = useDictionary(dictionary);

        const defaultDictionaryValue = values.find((el) => el.id === defaultValue);

        return (
            <FilterElement>
                <FilterElementContainer>
                    <FromGroupStyled>
                        {label && <FilterTitle className="form-control-label">{label}</FilterTitle>}
                        <FilterSelect
                            defaultValue={
                                defaultDictionaryValue && {
                                    value: defaultDictionaryValue.id,
                                    label: defaultDictionaryValue.title,
                                }
                            }
                            onChange={({ value }) => onChange(value)}
                            options={values.map((el) => ({ value: el.id, label: el.title }))}
                        />
                    </FromGroupStyled>
                    {!isHideCloseButton && <CloseButton handleClose={handleClose} />}
                </FilterElementContainer>
            </FilterElement>
        );
    },
);

export default DictionarySelector;
