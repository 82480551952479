import styled from 'styled-components';

export const StyledFormSelect = styled.select`
    background-color: #fff !important;
    border: 1px solid #dee2e6;
    color: #525f7f;
    padding: 2px 6px 2px 6px;

    &:focus {
        border: 1px solid #dee2e6;
        box-shadow: none;
        color: #525f7f;
    }
`;

export const StyledFlexColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
