import { AxiosInstance } from 'axios';
import { AlertOptions } from 'react-notification-alert';
import notifyStore from '../store/notification';
import prepareError from '../lib/prepareError';

export const addResponseResultNotifications = (axiosInstance: AxiosInstance) => {
    axiosInstance.interceptors.response.use(
        (response) => {
            notifyStore.showNotify({
                place: 'br',
                message: 'Действие выполнено успешно',
                type: 'success',
            } as AlertOptions);

            return response;
        },
        (error) => {
            notifyStore.showNotify({
                place: 'br',
                message: prepareError(error),
                type: 'danger',
            } as AlertOptions);

            return Promise.reject(error);
        },
    );
};

export const addResponseErrorNotifications = (axiosInstance: AxiosInstance) => {
    axiosInstance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            notifyStore.showNotify({
                place: 'br',
                message: prepareError(error),
                type: 'danger',
            } as AlertOptions);

            return Promise.reject(error);
        },
    );
};
