export interface Dictionary {
    id: number;
    title: string;
}

export enum DictionaryType {
    SOME_TYPE = 'SOME_TYPE',
    ADMIN = 'admin',
}

export enum AdminDictionaryId {
    BANK = 1,
    INSURANCE_COMPANY = 2,
}

export interface DictionaryParams {
    [DictionaryType.SOME_TYPE]?: number;
    [DictionaryType.ADMIN]?: number;
}

export type DictionarySelectorValue =
    | null
    | { label: string; value: number }
    | Array<null | { label: string; value: number } | any[]>;
