export function jsonToQueryString(json: Record<string, any>): string {
    if (!Object.keys(json).length) {
        return '';
    }

    return `?${Object.keys(json)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`)
        .join('&')
        .trim()}`;
}

export function queryStringToJson(queryString: string): Record<string, any> {
    const pairs = queryString.substring(1).split('&');
    const result: Record<string, any> = {};
    pairs.forEach((pair) => {
        const [key, value] = pair.split('=');
        result[decodeURIComponent(key)] = decodeURIComponent(value || '');
    });

    return result;
}
