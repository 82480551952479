import React, { useCallback } from 'react';
import { convertStringOrNullOrNumber } from 'lib/text';
import { StyledCellSelect } from './styles';

interface CellEditBooleanFieldProps {
    spanId: string;
    onChange: (data: any) => void;
    selectOptions?: Array<{ selectValue: any; title: any }>;
    value: any;
}

const CellEditSelectField: React.FC<CellEditBooleanFieldProps> = ({ value, selectOptions, spanId, onChange }) => {
    const uniqId = `table-select-id-${spanId}`;

    const handleChange = useCallback(
        (text: string) => {
            onChange(convertStringOrNullOrNumber(text));
        },
        [onChange],
    );

    return (
        <StyledCellSelect
            defaultValue={String(value)}
            onChange={(e) => handleChange(e.target.value)}
            id={uniqId}
            className="form-control-sm"
        >
            {selectOptions &&
                selectOptions.map(({ selectValue, title }) => (
                    <option key={String(selectValue)} value={selectValue}>
                        {String(title)}
                    </option>
                ))}
        </StyledCellSelect>
    );
};

export default CellEditSelectField;
