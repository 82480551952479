import React from 'react';

import CloseButton from 'components/buttons/CloseButton';
import { FromGroupStyled } from 'components/filters/components/styles';
import { FilterElementContainer, FilterElement, FilterTitle } from 'elements/filters';
import FilterSelect from '../../react-select/FilterSelect';

interface FilterSelectorProps {
    values: Array<string | number>;
    onChange: (value: any) => void;
    handleClose: () => void;
    isHideCloseButton?: boolean;
    placeholder?: string;
    label?: string;
    defaultValue?: string;
}

const ValueSelector: React.FC<FilterSelectorProps> = ({ isHideCloseButton, values, onChange, handleClose, label }) => {
    return (
        <FilterElement>
            <FilterElementContainer>
                <FromGroupStyled>
                    {label && <FilterTitle className="form-control-label">{label}</FilterTitle>}
                    <FilterSelect
                        onChange={({ value }) => onChange(value)}
                        options={Array.from(new Set(values)).map((el) => ({ value: el, label: String(el) }))}
                    />
                </FromGroupStyled>
                {!isHideCloseButton && <CloseButton handleClose={handleClose} />}
            </FilterElementContainer>
        </FilterElement>
    );
};

export default ValueSelector;
